import React from 'react';
import { FormLabel, OutlinedInput, FormControl } from '@material-ui/core';
import { IMultiSelectInputProps } from './interfaces';

export const MultiSelectInput = React.forwardRef<any, IMultiSelectInputProps>((props, ref) => {
  return (
    <FormControl fullWidth ref={ref} className={props.classNames?.formFieldRoot}>
      <FormLabel required={props.required}>{props.label}</FormLabel>
      <OutlinedInput
        error={props.options?.error}
        disabled={props.disabled}
        classes={{
          input: props.classNames?.inputRoot
        }}
        value={props.selectedDataString || ''}
        placeholder={props.translations?.inputPlaceholder}
        onChange={e => {
          if (!props.isOpened)
            props.onOpen();
        }}
        onClick={() => {
          props.onOpen();
        }} />
    </FormControl>
  );
});

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import {
  MultiSelectPopoverType,
  IMultiSelectPopoverHeadProps,
  IMultiSelectPopoverBodyProps,
  IMultiSelectPopoverProps,
} from './interfaces';
import { MultiSelectPopoverHead } from './multiselect-popover-head';
import { MultiSelectPopoverBody } from './multiselect-popover-body';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme, IMultiSelectPopoverProps>(() => ({
  popoverRoot: {
    maxWidth: props => props.options?.autoWidth ? 'auto' : 300,
  },
  popoverPaperRoot: {
    maxWidth: '100%',
    borderRadius: 0
  }
}));

export const MultiSelectPopover: MultiSelectPopoverType = props => {
  const { anchor, onClose } = props;
  const classes = makeClasses(props);

  const filteredValues = React.useMemo(() => {
    if (props.filterValue) {
      return props.values.filter(({ value }) => value.toLowerCase().includes(props.filterValue.toLowerCase()));
    }
    return props.values;
  }, [props.filterValue, props.values]);

  const getPreparedProps = (glued?: Partial<IMultiSelectPopoverHeadProps | IMultiSelectPopoverBodyProps>) => ({
    classNames: props.classNames,
    translations: props.translations,
    filterValue: props.filterValue,
    setFilterValue: props.setFilterValue,
    selected: props.selectedValues,
    values: props.values,
    filteredValues,
    onSelectAll: props.selectAll,
    onSelect: props.selectValue,
    options: props.options,
    ...glued,
  });

  const headProps = getPreparedProps(props.extendProps?.headerProps);
  const bodyProps = getPreparedProps(props.extendProps?.bodyProps);

  return (
    <Popover
      elevation={1}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={Boolean(anchor) && props.options?.disabled !== true}
      anchorEl={anchor}
      onClose={onClose}
      PaperProps={{
        className: classes.popoverPaperRoot
      }}
      {...props}
    >
      <div className={clsx(classes.popoverRoot, props.classNames?.popoverRoot)}>
        {
          props.overrides?.header !== undefined
            ? <props.overrides.header {...headProps} />
            : <MultiSelectPopoverHead {...headProps} />
        }
        {
          props.overrides?.body !== undefined
            ? <props.overrides.body {...bodyProps} />
            : <MultiSelectPopoverBody {...bodyProps} />
        }
      </div>
    </Popover>
  );
};

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Footer, Main, Section } from 'modules/core/components/layout';
import { LoginCard } from './login-card';
import { Navigation } from 'modules/core/components/layout/nav/';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const makeClasses = makeStyles(theme => ({
  authRoot: {
    flex: 1
  },
  container: {
    maxWidth: '1325px',
    paddingTop: 45,
    margin: '0 auto',
    marginBottom: theme.spacing(10),
  },
  titleHeader: {
    flex: 1,
    width: '100%',
    color: '#fff',
    textShadow: '0 5px 2px rgba(0, 0, 0, 0.15);',
    lineHeight: '57px',
    fontSize: 56,
    fontWeight: 900,
    letterSpacing: '-1.68px'
  },
  titleContent: {
    marginTop: 50,
    minWidth: 320,
    maxWidth: 420
  },
  text: {
    maxWidth: 399,
    fontSize: 15,
    fontWeight: 500,
    color: '#2D2D2D'
  },
  list: {
    listStyleType: 'none',
    paddingLeft: 0,
    '& li': {
      '&::before': {
        content: '""',
        width: 33,
        height: 38,
        marginRight: 10,
        display: 'inline-block',
        verticalAlign: 'middle',
        backgroundImage: `url('/static/img/icon_leaf.svg')`,
        backgroundSize: 'contain'
      }
    }
  },
  grid: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  loginCard: {
    minWidth: 320,
    maxWidth: 420
  },
  title: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  }
}));

export const AuthAdminView = (props: RouteComponentProps) => {
  const classes = makeClasses();
  const { t } = useTranslation();
  return (
    <>
      <Navigation noAuthRedirect />
      <Main withGradient withFooter withHeader>
        <Section className={classes.authRoot}>
          <div className={classes.container}>
            <Box mx={4}>
              <Grid container spacing={0} className={classes.grid}>
                <Grid item xs={12}>
                  <Box maxWidth="840px" className={classes.title}>
                    <Typography className={classes.titleHeader}>
                      {t('auth.titleTop')}
                      <br />
                      {t('auth.titleBottom')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4} className={classes.titleContent}>
                  <Typography className={classes.text}>
                    {t('auth.authSubtitle.subtitle')}
                  </Typography>
                  <ul className={clsx(classes.text, classes.list)}>
                    <li>{t('auth.authSubtitle.point1')}</li>
                    <li>{t('auth.authSubtitle.point2')}</li>
                    <li>{t('auth.authSubtitle.point3')}</li>
                  </ul>
                </Grid>
                <Grid item xs={12} lg={4} className={classes.loginCard}>
                  <LoginCard authWithMicrosoft={true} />
                </Grid>
              </Grid>
            </Box>
          </div>
        </Section>
        <Footer />
      </Main>
    </>
  );
};

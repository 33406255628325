import { Reducer } from 'redux';
import { Actions, CoreStoreActions } from '../actions';
import { Errors } from 'const';
import { IUserProfile } from 'interfaces/user.interface';

export interface ICoreStoreState {
  token: string | boolean;
  showLoader: boolean;
  coreServicesReady: boolean,

  error: {
    type: Errors
    message?: string,
  } | null;
  tenantId: string | false;
  profile?: IUserProfile | undefined;
}

export const coreStoreInitialState: ICoreStoreState = {
  token: false,
  showLoader: true,
  error: null,
  coreServicesReady: false,
  tenantId: false,
  profile: undefined
};

export const coreStoreReducer: Reducer<ICoreStoreState, Actions> = (
  state: ICoreStoreState = coreStoreInitialState,
  action: Actions
) => {
  switch (action.type) {
    case CoreStoreActions.SET_CORE_SERVICES_READY: {
      return {
        ...state,
        coreServicesReady: action.payload.statement,
      }
    }

    case CoreStoreActions.SET_SHOW_LOADER:
      return {
        ...state,
        showLoader: action.payload.statement
      };

      case CoreStoreActions.SET_AUTH_TOKEN: {
        return {
          ...state,
          token: action.payload.token
        };
      }

      case CoreStoreActions.SET_CURRENT_TENANT: {
        return {
          ...state,
          tenantId: action.payload.tenantId
        };
      }

    case CoreStoreActions.SET_ERROR_TYPE:
      return {
        ...state,
        error: action.payload.error ? { ...action.payload.error } : null,
      };

    case CoreStoreActions.SET_USER_PROFILE: {
      return {
        ...state,
        profile: action.payload.profile
      }
    }

    default:
      return state;
  }
};

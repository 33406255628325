import { default as createMuiTheme } from '@material-ui/core/styles/createMuiTheme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { Fonts } from 'interfaces/themes/fonts.interface';
import { DefaultTheme, DefaultThemeOptions } from 'interfaces/themes/default-theme.interfaces';

const breakPoints = createBreakpoints({});

export const THEME_APPBAR_DESKTOP_HEIGHT = 81;
export const THEME_APPBAR_MOBILE_HEIGHT = 60;

const fontFamily = '"niveau-grotesk", sans-serif';

export const fontSizes: Fonts = {
  xxl: {
    fontSize: '56px',
    lineHeight: '84px',
    fontFamily
  },
  xl: {
    fontSize: '43px',
    lineHeight: '64px',
    fontFamily
  },
  l: {
    fontSize: '30px',
    lineHeight: '45px',
    fontFamily
  },
  m: {
    fontSize: '25px',
    lineHeight: '37px',
    fontFamily
  },
  s: {
    fontSize: '20px',
    lineHeight: '30px',
    fontFamily
  },
  xs: {
    fontSize: '15px',
    lineHeight: '24px',
    fontFamily
  },
  xxs: {
    fontSize: '12px',
    lineHeight: '19px',
    fontFamily
  }
};

export const theme: DefaultThemeOptions = {
  mixins: {
    colors: {
      red: '#DB4437',
      green: '#0F9D58',
      yellow: '#F4B400'
    },
    tables: {
      tableContainer: {
        overflow: 'auto',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        transition: '.3s linear',
        '&.fetch': {
          opacity: 0.5
        }
      },
    },
    fonts: {
      regular: {
        xxl: {
          ...fontSizes.xxl,
          fontWeight: 400
        },
        xl: {
          ...fontSizes.xl,
          fontWeight: 400
        },
        l: {
          ...fontSizes.l,
          fontWeight: 400
        },
        m: {
          ...fontSizes.m,
          fontWeight: 400
        },
        s: {
          ...fontSizes.s,
          fontWeight: 400
        },
        xs: {
          ...fontSizes.xs,
          fontWeight: 400
        },
        xxs: {
          ...fontSizes.xxs,
          fontWeight: 400
        }
      },
      medium: {
        xxl: {
          ...fontSizes.xxl,
          fontWeight: 500
        },
        xl: {
          ...fontSizes.xl,
          fontWeight: 500
        },
        l: {
          ...fontSizes.l,
          fontWeight: 500
        },
        m: {
          ...fontSizes.m,
          fontWeight: 500
        },
        s: {
          ...fontSizes.s,
          fontWeight: 500
        },
        xs: {
          ...fontSizes.xs,
          fontWeight: 500
        },
        xxs: {
          ...fontSizes.xxs,
          fontWeight: 500
        }
      },
      bold: {
        xxl: {
          ...fontSizes.xxl,
          fontWeight: 700
        },
        xl: {
          ...fontSizes.xl,
          fontWeight: 700
        },
        l: {
          ...fontSizes.l,
          fontWeight: 700
        },
        m: {
          ...fontSizes.m,
          fontWeight: 700
        },
        s: {
          ...fontSizes.s,
          fontWeight: 700
        },
        xs: {
          ...fontSizes.xs,
          fontWeight: 700
        },
        xxs: {
          ...fontSizes.xxs,
          fontWeight: 700
        }
      }
    }
  },
  backgrounds: {
    primary: 'linear-gradient(180deg, #3ecaff 0%, #2dabff 100%)',
    secondary: 'linear-gradient(180deg, #97cf25 0%, #6bbc1b 100%)',
    tertiary: 'linear-gradient(180deg, #ffaa00 0%, #ff8000 100%)',
    main: 'linear-gradient(180deg, #AFD435 0%, #4C53FB 50%)',
    sky: 'linear-gradient(to bottom, #FFFFFF 0%, #6EDAE6 100%)',
    ground: 'linear-gradient(to bottom, #AFD435 0%, transparent 100%)'
  },
  palette: {
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    primary: {
      main: '#004AB5',
      dark: '#717885',
      light: '#003889',
    },
    secondary: {
      main: '#323B4C',
      dark: '#111826',
    },
    error: {
      main: '#FF0E4F',
      dark: '#111826',
      light: '#FF0E4F33',
    },
    success: {
      main: '#43BF88',
      light: '#43BF8826',
    },
    warning: {
      main: '#FFBE00',
    },
    grey: {
      100: '#F0F2F6',
      200: '#DBDEE3',
      300: '#C6C9D0',
      400: '#B1B5BD',
      500: '#9CA1AA',
      600: '#868C98',
      700: '#717885',
      800: '#5C6472',
      900: '#474F5F',
      A100: '#F8F9FB',
    },
  },
  shape: {
    borderRadius: 4
  },
  typography: {
    fontFamily,
    h1: {
      fontSize: 80,
      letterSpacing: '-0.01em',
      lineHeight: '80px',
      marginBottom: 24,
      fontWeight: 300,
    },
    h2: {
      fontSize: 60,

      letterSpacing: '-0.01em',
      lineHeight: '66px',
      marginBottom: 24,
      fontWeight: 300,
    },
    h3: {
      fontSize: 40,

      letterSpacing: '-0.01em',
      lineHeight: '44px',
      fontWeight: 300,
      '@media (max-width:900px)': {
        fontSize: 22,
      },
    },
    h4: {
      fontSize: 40,
      letterSpacing: '-0.01em',
      lineHeight: '44px',
      marginBottom: 16,
      fontWeight: 500,
    },
    h5: {
      fontSize: 24,

      letterSpacing: '-0.01em',
      lineHeight: '28px',
      marginBottom: 16,
      fontWeight: 300,
    },
    body1: {
      fontSize: 20,

      letterSpacing: '-0.01em',
      lineHeight: '23px',
      fontWeight: 400,
    },
    body2: {
      // Body L32 from figma
      fontSize: 20,

      letterSpacing: '-0.01em',
      lineHeight: '32px',
      fontWeight: 400,
    },
    subtitle1: {
      // Body M from figma
      fontSize: 16,
      letterSpacing: '-0.01em',
      lineHeight: '19px',
      fontWeight: 400,
    },
    subtitle2: {
      // Body S from figma
      fontSize: 12,

      letterSpacing: '-0.01em',
      lineHeight: '14px',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        fontSize: 15
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: 15,
        lineHeight: '24px'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 4,
        marginBottom: '20px'
      },
      input: {
        '&::placeholder': {
          color: '#323B4C'
        },
        color: '#323B4C',
        padding: '0 12px',
        fontWeight: 400,
        background: '#fff',
        border: '1px solid #717885',
        height: '56px'
      },
      notchedOutline: {
        border: '0'
      }
    },
    MuiToolbar: {
      regular: {
        [breakPoints.up('lg')]: {
          minHeight: THEME_APPBAR_DESKTOP_HEIGHT
        }
      }
    },
    MuiButton: {
      root: {
        fontFamily,
        fontWeight: 300,
        height: '58px',
        fontSize: '20px',
        borderRadius: '50px',
        textTransform: 'none'
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        color: '#fff'
      }
    },
    MuiDivider: {
      root: {
        margin: '0 0 16px'
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          borderRadius: 4
        }
      }
    }
  }
};

export const defaultTheme = createMuiTheme(theme) as DefaultTheme;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import 'i18n';
import { unregister } from 'serviceWorker';
import store from 'modules/core/store';
import { defaultTheme } from 'themes/default-theme';
import { Config } from 'config';
import { App } from 'App';
import { localServiceWorkerRegister } from 'localServiceWorkerRegister';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { bootstrapCoreServices } from './bootstrap-services';

import 'moment/locale/pl.js';
import 'video.js/dist/video-js.min.css';

document.title = Config.APP_TITLE;
bootstrapCoreServices();

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={defaultTheme}>
          <App/>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'development') {
  localServiceWorkerRegister();
} else {
  unregister();
  // register();
}


import React, { useEffect } from 'react';
import { Button, ButtonBase, makeStyles, Popover, Theme, Divider } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { useService } from 'modules/core/hooks';
import { UsersService, AuthService } from 'modules/core/services';
import { useHistory, Link } from 'react-router-dom';
import clsx from 'clsx';
import { IUserProfile } from 'interfaces/user.interface';
import { showApiErrors } from 'utils';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { userProfileSelector } from 'modules/core/store/selectors';
import store from 'modules/core/store';
import { setUserProfile } from 'modules/core/store/actions';
import { ROLES } from 'interfaces/role.interface';
import { Config } from 'config';

const makeClasses = makeStyles<Theme, IUserPanelProps>(theme => ({
  root: {
    display: props => props.visible ? 'flex' : 'none',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end'
  },
  text: {
    fontSize: '15px'
  },
  buttonExpand: {
    fontFamily: '"niveau-grotesk"',
    fontWeight: 700,
    height: '50px',
    padding: '0 35px',
    fontSize: '18px',
    marginLeft: '20px',
    borderRadius: '50px',
    border: '2px solid #323B4C',
    '&:focus': {
      outline: '0px'
    }
  },
  button: {
    font: 'Bold 15px/24px niveau-grotesk'
  },
  buttonBack: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid lightgrey'
  },
  buttonLogout: {
    padding: '0 25px',
    margin: `0 0 ${theme.spacing(2)}px`,
    fontSize: '18px',
    textAlign: 'center',
    background: 'none',
    border: '2px solid #323B4C',
    width: '100%'
  },
  popover: {
    overflow: 'visible',
    borderTopRightRadius: '0',
    borderTopLeftRadius: '0',
    padding: theme.spacing(4, 5),
    minWidth: '520px',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    }
  },
  headline: {
    fontSize: '24px',
    fontWeight: 400,
    margin: `0 0 ${theme.spacing(2)}px`
  },
  loggedWrapTitle: {
    margin: `0 0 ${theme.spacing(2)}px`,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'row wrap',
      justifyContent: 'center'
    }
  },
  link: {
    // fontWeight: 700,
    lineHeight: '50px',
    padding: '0 35px',
    fontSize: '18px',
    borderRadius: '50px',
    display: 'block',
    textDecoration: 'none',
    margin: `0 0 ${theme.spacing(2)}px`,
    textAlign: 'center',
    background: theme.palette.secondary.main,
    color: '#FFF'
  },
  linkChangePass: {
    background: 'none',
    border: '2px solid #323B4C',
    color: '#323B4C',
    margin: '0'
  },
  loggedInTitle: {
    display: 'block',
    lineHeight: '18px'
  },
  loggedText: {
    paddingTop: '8px'
  },
  textWrapper: {
    textAlign: 'left'
  }
}));

export interface IUserPanelProps {
  visible: boolean
}

export const UserPanel: React.FC<IUserPanelProps> = (props: IUserPanelProps) => {
  const { visible } = props
  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const [profile, setProfile] = React.useState<IUserProfile | false>(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const authService = useService(AuthService);
  const usersService = useService(UsersService);
  const profileStore = useSelector(userProfileSelector);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchor);
  const { t } = useTranslation();
  const classes = makeClasses({ visible });
  const history = useHistory();

  const openMenu = (e: React.MouseEvent) => {
    setAnchor(e.currentTarget);
  };

  const onLogout = async (e: React.MouseEvent) => {
    const isMSAccount = localStorage.getItem(Config.STORED_ACCOUNT_TYPE) === 'MS';
    await authService.logout();
    if (isMSAccount) {
      window.location.href = `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${window.location.origin + '/auth'}`
    } else {
      history.replace('/auth');
    }
  };

  const getUserInfo = async () => {
    if (profileStore) {
      setProfile(profileStore);
    } else {
      try {
        const { value } = await usersService.getUserProfile();
        store.dispatch(setUserProfile(value));
        setProfile(value)
      } catch (e) {
        showApiErrors(e as any, enqueueSnackbar);
      }
    }
  }

  useEffect(() => {
    if (visible) {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const onReset = async (e: React.MouseEvent) => {
  //   if (profile) {
  //     try {
  //       const token = await usersService.resetUserPassword(profile.email)
  //       history.push(`/reset-password?token=${token}`)
  //     } catch (e) {
  //       showApiErrors(e, enqueueSnackbar);
  //     }
  //   }
  // }

  return (
    <div className={classes.root}>
      <div className={classes.textWrapper}>
        <span className={classes.text}>{t('common.loggedInAs')} <br /> {profileStore?.email}</span>
      </div>

      <ButtonBase className={classes.buttonExpand} ref={buttonRef} onClick={openMenu}>
        {t('common.myAccount')}
      </ButtonBase>
      {open && (<Popover
        open={open}
        onClose={() => setAnchor(null)}
        anchorEl={anchor}
        classes={{ paper: classes.popover }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        marginThreshold={0}>
        <h2 className={classes.headline}>
          {t('common.yourAccount')}
        </h2>
        <Divider />
        {profile && (<div className={classes.loggedWrapTitle}>
          <div className={classes.loggedText}>
            <span className={classes.loggedInTitle}>{t('auth.userPanel.titleLogin')}</span>
            <strong>{profile.email}</strong>
          </div>
          <Link className={clsx(classes.link, classes.linkChangePass)} to={'/user/change-password'}>{t('auth.changePassword.button')}</Link>
        </div>)}
        <Divider />
        <Link className={classes.link} to={'/user/tenants'}>{t('auth.userPanel.yourTenants')}</Link>
        {profileStore?.roles?.includes(ROLES.CREATE_TENANT) && <Link className={classes.link} to={'/user/tenants-register'}>{t('auth.userPanel.registerTenant')}</Link>}
        <Divider />
        <Button className={clsx(classes.button, classes.buttonLogout)} variant="contained" onClick={onLogout} >{t('common.logout')}</Button>
      </Popover>)}
    </div>
  );
};

import React from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  OutlinedInput,
  Grid,
  Container,
  Box,
  Typography
} from '@material-ui/core';
import { Main, ResponsiveModal } from 'modules/core/components/layout';
import { useService } from 'modules/core/hooks';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';
import { Navigation } from 'modules/core/components/layout/nav';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UsersService } from 'modules/core/services';
import { showApiErrors } from 'utils/show-errors';
import { LoaderView } from 'modules/core/views';
import clsx from 'clsx';
import { LockIcon } from 'assets/svg';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  container: {
    height: '100vh',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '24px'
  },
  headline: {
    marginLeft: '8px',
    marginBottom: 'none'
  },
  grid: {
    alignItems: 'center',
    height: '75vh',
    justifyContent: 'center',
  },
  card: {
    minWidth: 320,
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1)
  },
  description: {
    ...theme.mixins.fonts.regular.xs,
  },
  label: {
    ...theme.mixins.fonts.regular.xs,
  },
  input: {
    width: '100%',
    height: 40,
  },
  controlButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(10)
  },
  resetButton: {
    padding: '19px 34px',
    width: '293px'
  },
  cancelButton: {
    padding: '19px 34px',
    background: theme.palette.grey[200],
    '&:hover': {
      background: theme.palette.grey[400],
      boxShadow: 'none'
    }
  },
  dialogContent: {
    ...theme.mixins.fonts.medium.xs,
    maxWidth: 400,
  },
  dialogActions: {
    padding: theme.spacing(1, 3, 3, 3),
  },
}));

export const ForgotPasswordView = () => {
  const classes = makeClasses();
  const history = useHistory();
  const { t } = useTranslation();

  const [email, setEmail] = React.useState<string>('');
  const [isSending, setIsSending] = React.useState(false);
  const [isSentProperly, setIsSentProperly] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const usersService = useService(UsersService);

  const onGoBack = () => {
    history.goBack();
  };

  const sendEmailToResetPassword = async () => {
    if (isSending) {
      return;
    }
    try {
      setIsSending(true);
      await usersService.resetUserPassword(email.trim());
      setIsSentProperly(true);
    } catch (e) {
      showApiErrors(e as any, enqueueSnackbar);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <LoaderView visible={isSending} />
      <ResponsiveModal
        open={isSentProperly}
        onClose={onGoBack}
      >
        <DialogContent
          className={classes.dialogContent}
        >
          {t('auth.forgotPassword.linkHasBeenSent', {
            email,
          })}
        </DialogContent>
        <DialogActions
          className={classes.dialogActions}
        >
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={onGoBack}
          >
            {t('auth.forgotPassword.goBack')}
          </Button>
        </DialogActions>
      </ResponsiveModal>
      <Navigation />
      <Main withHeader withFooter withGradient>
        <Container maxWidth='xs' className={classes.container} >
        <Box
        className={classes.title}
      >
        <LockIcon style={{ fontSize: '35px' }} />
        <Typography variant='h3' className={classes.headline}>{t('auth.forgotPassword.passReset')}</Typography>
      </Box>
      <Typography variant='body2' style={{marginBottom: '80px', textAlign: 'center'}}>{t('auth.forgotPassword.description')}</Typography>
            <form className={classes.formRoot}
            onSubmit={e => {
              e.preventDefault();
              sendEmailToResetPassword();
            }}>
              <FormControl fullWidth margin='normal' >
                <OutlinedInput
                  classes={{ root: classes.input }}
                  value={email}
                  placeholder={t('auth.forgotPassword.emailLabel')}
                  onChange={e => setEmail(e.target.value)}
                  type="text"
                />
              </FormControl>
            </form>
            <div className={classes.controlButtons}>
              <Button
                className={classes.cancelButton}
                onClick={onGoBack}
                variant='contained'
                color='default'
              >
                {t('auth.forgotPassword.goBack')}
              </Button>
              <Button
                className={classes.resetButton}
                variant="contained"
                color="secondary"
                onClick={sendEmailToResetPassword}
              >
                {t('auth.forgotPassword.sendLink')}
              </Button>
            </div>
        </Container>
      </Main>
    </>
  );
};

import { AuthService, getService } from 'modules/core/services';
import store from './modules/core/store';
import { setCoreServicesReady, setShowLoader } from './modules/core/store/actions';

export function bootstrapCoreServices() {
  const authService = getService(AuthService);
  Promise.all([authService.bootstrap()]).then(() => {
    store.dispatch(setCoreServicesReady(true));
    store.dispatch(setShowLoader(false));
  });
}

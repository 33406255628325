import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { InputFormField, PasswordFormField } from 'modules/core/components/common';
import { Main } from 'modules/core/components/layout';
import { getService, UsersService } from 'modules/core/services';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';
import { showApiErrors } from 'utils/show-errors';
import { Navigation } from 'modules/core/components/layout/nav';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userProfileSelector } from 'modules/core/store/selectors';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  container: {
    position: 'relative',
    maxWidth: '500px',
    margin: '15vh auto 0',
    width: '100%',
    padding: theme.spacing(3),
  },
  info: {
    border: `2px solid ${theme.palette.info.main}`,
    padding: theme.spacing(2),
    color: theme.palette.info.main
  },
  card: {
    maxWidth: 500,
    width: '100%',
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1)
  },
  controlButtons: {
    display: 'flex',
    padding: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(2),
    width: '100%',
  }
}));

interface State {
  currentPassword: string;
  password: string;
  confirmPassword: string;
  showPassword: boolean;
  showCurrentPassword: boolean;
  showConfirmPassword: boolean;
}

export const ChangePasswordView = () => {
  const classes = makeClasses();

  const { current: usersService } = React.useRef(getService(UsersService));

  const [values, setValues] = React.useState<State>({
    currentPassword: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
    showCurrentPassword: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const [currentPasswordError, setCurrentPasswordError] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const profile = useSelector(userProfileSelector);

  const isInternalAccount = React.useMemo(() => profile?.loginProviders.includes('GrowUperion'), [profile])

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === 'password') {
      setPasswordError(false);
      if (event.target.value === values.confirmPassword) {
        setConfirmPasswordError(false);
      }
    }

    if (prop === 'confirmPassword' && event.target.value === values.password) {
      setConfirmPasswordError(false);
    }

    if (prop === 'currentPassword' && values.confirmPassword.length === 0) {
      setCurrentPasswordError(false);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowCurrentPassword = () => {
    setValues({ ...values, showCurrentPassword: !values.showCurrentPassword });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleResetPassword = async () => {

    if (!values.password) {
      setPasswordError(true);
      enqueueSnackbar(t('auth.resetPassword.emptyPasswordsError'), {
        variant: 'error'
      });
      return;
    }

    if (values.password !== values.confirmPassword) {
      setConfirmPasswordError(true);
      enqueueSnackbar(t('auth.resetPassword.passwordsNotMatch'), {
        variant: 'error'
      });
      return;
    }

    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,}$/g;
    if (!values.password.match(regex)) {
      setConfirmPasswordError(true);
      enqueueSnackbar(t('auth.resetPassword.passwordRegexError'), {
        variant: 'error'
      });
      return;
    }

    try {
      await usersService.changePassword(
        values.currentPassword,
        values.password
      );
      enqueueSnackbar(t('auth.resetPassword.passwordsChangeSuccess'), {
        variant: 'success'
      });
      history.push('/auth');
    } catch (e) {
      showApiErrors(e, enqueueSnackbar);
    }
  };

  return (
    <>
      <Navigation />
      <Main withHeader withFooter withGradient>
        <div className={classes.container}>
          <Typography variant='h3' style={{marginBottom: '24px'}}>{t('auth.changePassword.title')}</Typography>
            {!isInternalAccount && (
              <p className={classes.info}>{t('auth.changePassword.externalAccountInfo')}</p>
            )}
            <form className={classes.formRoot}>
              <PasswordFormField
                error={currentPasswordError}
                handleChange={handleChange}
                name='currentPassword'
                handleClickShowPassword={handleClickShowCurrentPassword}
                password={values.currentPassword}
                showPassword={values.showCurrentPassword}
                label={t('auth.changePassword.oldPassword')}
              />
              <PasswordFormField
                error={passwordError || confirmPasswordError}
                handleChange={handleChange}
                handleClickShowPassword={handleClickShowPassword}
                password={values.password}
                showPassword={values.showPassword}
                label={t('auth.changePassword.newPassword')}
              />
              <PasswordFormField
                error={confirmPasswordError}
                handleChange={handleChange}
                name='confirmPassword'
                handleClickShowPassword={handleClickShowConfirmPassword}
                password={values.confirmPassword}
                showPassword={values.showConfirmPassword}
                label={t('auth.changePassword.confirmPassword')}
              />
            </form>
            <div className={classes.controlButtons}>
              <Button className={classes.button} variant="contained" color="secondary" onClick={handleResetPassword}>
                {t('auth.changePassword.button')}
              </Button>
            </div>
        </div>
      </Main>
    </>
  );
};

import React from 'react';
import { Button, Menu, makeStyles, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';
import { defaultTheme } from 'themes/default-theme';

export interface IDropDownElement {
  id: string;
  name: string;
}

export interface IDropDownProps {
  elements: IDropDownElement[];
  onElementSelect: (element: IDropDownElement) => void;
  width?: number;
  transparent?: boolean;
  firstElementSelected: IDropDownElement;
  isSelected?: boolean;
}

export type DropDownProps = React.PropsWithChildren<IDropDownProps>;

const makeClasses = makeStyles<DefaultTheme, { isOpen: boolean; menuWidth: number; transparent: boolean, showSelection: boolean }>(theme => ({
  button: {
    '&, &:hover': {
      ...defaultTheme.mixins.fonts.bold.xs,
      display: 'inline-flex',
      alignItems: 'center',
      height: theme.spacing(5),
      padding: theme.spacing(0, 1),
      // border: props => // TODO investigate if this feature is even needed
      //   !props.transparent
      //     ? `1px solid ${defaultTheme.palette.grey['400']}`
      //     : props.isOpen
      //     ? '1px solid transparent'
      //     : `1px solid ${theme.palette.common.white}`,
      borderBottomLeftRadius: props => (props.isOpen ? '0' : theme.spacing(0.5)),
      borderBottomRightRadius: props => (props.isOpen ? '0' : theme.spacing(0.5)),
      background: props => (props.isOpen || !props.transparent ? theme.palette.common.white : 'rgba(0,0,0,0.058)'),
      color: props => (props.isOpen || !props.transparent ? theme.palette.common.black : theme.palette.common.white),
      border: props => props.showSelection ? `3px solid ${theme.palette.primary.main}` : `1px solid ${defaultTheme.palette.grey['400']}`
    }
  },
  buttonSVG: {
    marginLeft: theme.spacing(2)
  },
  menu: {
    minWidth: props => props.menuWidth,
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0'
  },
  list: {
    border: props => (!props.transparent ? `1px solid ${theme.palette.grey[300]}` : 'none'),
    padding: '0',
    '& li': {
      ...defaultTheme.mixins.fonts.bold.xs,
      display: 'flex',
      alignItems: 'center',
      height: theme.spacing(5),
      padding: theme.spacing(0, 3),
      borderTop: `1px solid ${theme.palette.grey[300]}`
    }
  }
}));

export const Dropdown: React.FC<DropDownProps> = (props: DropDownProps) => {
  const [menuWidth, setMenuWidth] = React.useState(0);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const [selectedElement, setSelectedElement] = React.useState<IDropDownElement>(props.firstElementSelected);
  const getButtonWidth = () => {
    if (buttonRef && buttonRef.current) {
      const width = buttonRef.current.offsetWidth;
      setMenuWidth(width);
    }
  };

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const closeMenu = () => setAnchor(null);
  const isOpen = Boolean(anchor);

  const classes = makeClasses({
    isOpen,
    menuWidth,
    transparent: props.transparent === true ? true : false,
    showSelection: props.isSelected === true ? true : false
  });

  const onElementClick = (event: React.MouseEvent<HTMLElement>, element: IDropDownElement) => {
    setSelectedElement(element);
    closeMenu();
    props.onElementSelect(element);
  };

  const menuItem = (element: IDropDownElement) => (
    <MenuItem onClick={event => onElementClick(event, element)} key={element.id}>
      {element.name}
    </MenuItem>
  );

  return (
    <>
      <Button
        ref={buttonRef}
        classes={{ root: classes.button }}
        onClick={e => {
          getButtonWidth();
          setAnchor(e.currentTarget);
        }}
      >
        {selectedElement.name}
        <ArrowDropDownIcon className={classes.buttonSVG}/>
      </Button>
      <Menu
        elevation={0}
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        marginThreshold={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{
          paper: classes.menu,
          list: classes.list
        }}
      >
        {props.elements.map((element: IDropDownElement) => menuItem(element))}
      </Menu>
    </>
  );
};

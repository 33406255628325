import React from 'react';
import { MultiSelectPopoverBodyType } from './interfaces';
import { MultiSelectChipsList } from './multiselect-chips-list';
import { MultiSelectList } from './multiselect-list';
import { Divider } from '@material-ui/core';

export const MultiSelectPopoverBody: MultiSelectPopoverBodyType = props => {
  const { Before, After } = props;
  return (
    <>
      {Before ? Before : null}
      <Divider />
      <MultiSelectChipsList
        selected={props.selected}
        onSelect={props.onSelect}
      />
      <Divider />
      <MultiSelectList
        onSelect={props.onSelect}
        values={props.filteredValues}
        selectedValues={props.selected}
        options={props.options}
      />
      {After ? After : null}
    </>
  );
};

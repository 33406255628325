import React, { useEffect } from 'react';
import { Redirect, Route, Switch, RouteProps, useLocation } from 'react-router-dom';
import { withSuspense } from 'modules/core/hoc/withSuspense';
import {
  AuthView,
  ErrorView,
  ForgotPasswordView,
  ResetPasswordView,
  SelectionTenents,
  SignOutView,
  RegisterTenantView,
  SelectionTenentUsers,
  ChangePasswordView,
  PermissionView,
  BlockedView
} from '../views';
import { useService } from '.';
import { AuthService, UsersService } from '../services';
import { languages } from 'config';
import i18n from 'i18n';
import { HttpClientService } from '../functional-services';
import { userProfileSelector } from '../store/selectors';
import { useSelector } from 'react-redux';
import { setUserProfile } from '../store/actions';
import store from '../store';


export const useModules = () => {
  const authService = useService(AuthService);
  const httpClientService = useService(HttpClientService);
  const usersService = useService(UsersService);
  const profileStore = useSelector(userProfileSelector);
  const location = useLocation();

  const AuthorizedRoute = React.useCallback(
    (routeProps: RouteProps) => {
      return (authService.isAuthorized ? <Route {...routeProps} /> : <Redirect to={'/auth'}/>);
    },
    [authService.isAuthorized]
  );

  const forceLanguage = () => {
    const { search } = location;
    const lang = new URLSearchParams(search).get('lang');
    if (!authService.isAuthorized && lang && languages.includes(lang)) {
      httpClientService.setHeaderLanguage(lang);
      i18n?.changeLanguage(lang);
    }
  }

  const getProfileUser = async () => {
    if (!profileStore) {
      try {
        const { value } = await usersService.getUserProfile()
        store.dispatch(setUserProfile(value));
      } catch (e) {
        authService.appService.handleError(e);
      }
    }
  }

  useEffect(() => {
    if (authService.isAuthorized) {
      getProfileUser();
    }
    forceLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authService.isAuthorized])

  const Modules = () => {
    return (
      <Switch>
        <Route key="authView" exact path={'/auth'} component={AuthView}/>
        <Route key="forgotPasswordView" exact path={'/forgot-password'} component={ForgotPasswordView}/>
        <Route key="signout" path={'/signout'} component={SignOutView}/>
        <Route key="aadth" path={'/aadth'} component={PermissionView}/>
        <Route key="blocked" path={'/blocked'} component={BlockedView}/>
        <Route key="resetPasswordView" exact path={'/reset-password'} component={ResetPasswordView}/>
        <AuthorizedRoute key="changePasswordView" exact path={'/user/change-password'} component={ChangePasswordView}/>
        <AuthorizedRoute key="registerTenantView" exact path={'/user/tenants-register'} component={RegisterTenantView}/>
        <AuthorizedRoute key="tenants" exact path={'/user/tenants'} component={SelectionTenents}/>
        <AuthorizedRoute key="tenantsUser" exact path={'/user/tenant-users/:id'} component={SelectionTenentUsers}/>
        <Route key="redirectToHome" exact path={'/'} render={() => <Redirect to={'/auth'}/>}/>
        <Route key="errorView" component={ErrorView} />
      </Switch>
    );
  };

  return {
    Modules: withSuspense(Modules)
  };

};

import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Button, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { Section, Main } from 'modules/core/components/layout';
import { useService } from 'modules/core/hooks';
import { useTranslation } from 'react-i18next';
import { TenantsService } from 'modules/core/services';
import clsx from 'clsx';
import {
  InputFormField,
  PasswordFormField,
} from 'modules/core/components/common';
import { Navigation } from 'modules/core/components/layout/nav';
import { LoaderView } from '..';
import { showApiErrors } from 'utils';
import { useSnackbar } from 'notistack';


export const RegisterMain = styled(Main)`
  background-image: linear-gradient(179deg, #9ed53e 0%, #5b8f28 100%);
`;

const makeClasses = makeStyles(theme => ({
  root: {
    flex: 1,
    padding: theme.spacing(4),
  },
  container: {
    maxWidth: 500,
    margin: '0 auto'
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 0
  },
  rootInput: {
    width: '100%'
  },
  checkbox: {
    marginBottom: theme.spacing(3)
  },
  controlButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '40% 40%',
    paddingTop: '20px'
  },
  button: {
    width: '45%',
    font: 'Bold 15px/24px niveau-grotesk',
  },
  cancelButton: {
    background: theme.palette.grey[200],
    '&:hover': {
      background: theme.palette.grey[400],
      boxShadow: 'none'
    }
  },

}));

interface ISchema {
  [key: string]: { value: string, error: string },
}

const initState: ISchema = {
  name: { value: '', error: '' },
  addCurrentAccount: { value: 'false', error: '' },
  isOnboarding: { value: 'false', error: '' },
  email: { value: '', error: '' },
  password: { value: '', error: '' },
  confirmPassword: { value: '', error: '' },
}

interface ISchemaValidationSimple {
  regex: RegExp,
  error: string
}
interface ISchemaValidation {
  [key: string]: { required: boolean, validator?: ISchemaValidationSimple },
}
const initValid: ISchemaValidation = {
  name: {
    required: true
  },
  addCurrentAccount: {
    required: false
  },
  isOnboarding: {
    required: false
  },
  email: {
    required: true,
    validator: {
      regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      error: 'Invalid email format'
    }
  },
  password: {
    required: true
  },
  confirmPassword: {
    required: true
  },
}

export const RegisterTenantView = () => {
  const classes = makeClasses();
  const history = useHistory();
  const tenantsService = useService<TenantsService>(TenantsService);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [values, setValues] = React.useState(initState);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [addCurrent, setAddCurrent] = React.useState(false);
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const createTenant = async () => {
    if (!validateState()) {
      const { name, addCurrentAccount, isOnboarding, email, password } = values
      try {
        setLoading(true)
        await tenantsService.createTenant(name.value, addCurrentAccount.value === 'true', isOnboarding.value === 'true', email.value, password.value)
      } catch (e) {
        setLoading(false)
        showApiErrors(e, enqueueSnackbar);
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    setDisable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty) {
      setDisable(validateState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isDirty]);

  const validateState = useCallback(() => {
    let hasErrorInState: boolean;
    if (addCurrent) {
      const stateValue = values.name.value; // state value
      const stateError = values.name.error; // state error
      hasErrorInState = !stateValue || !!stateError;
    } else {
      hasErrorInState = Object.keys(initValid).some(key => {
        const isInputFieldRequired = initValid[key].required;
        const stateValue = values[key].value; // state value
        const stateError = values[key].error; // state error
        return (isInputFieldRequired && !stateValue) || stateError;
      });
    }
    return hasErrorInState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, initValid]);

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    const { value, name } = event.target
    let error = ''
    if (initValid[name].required) {
      if (!value) {
        error = 'This is required field.';
      }
    }

    if (
      initValid[name].validator !== null &&
      typeof initValid[name].validator === 'object'
    ) {
      if (value && !initValid[name]?.validator?.regex?.test(value)) {
        const errorMessage = initValid[name]?.validator?.error
        error = errorMessage ? errorMessage : ''
      }
    }

    setValues(prevState => ({
      ...prevState,
      [name]: { value, error },
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'addCurrentAccount') {
      setAddCurrent(event.target.checked)
    }
    setValues({ ...values, [event.target.name]: { value: String(event.target.checked), error: '' } });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  return (
    loading ? (<LoaderView visible text={t('auth.registerTenant.loaderText')} />) : (
      <>
        <Navigation />
        <Main withHeader withFooter withGradient>
          <RegisterMain>
            <Section className={classes.root}>
              <div className={classes.container}>
                <Typography variant='h3' style={{ marginBottom: '24px' }}>{t('auth.registerTenant.headline')}</Typography>
                <form className={classes.formRoot}>
                  <InputFormField
                    name="name"
                    handleChange={handleChange}
                    error={values.name.error.length > 0}
                    value={values.name.value}
                    placeholder={`${t('auth.registerTenant.label')} *`}
                  />
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox name="addCurrentAccount" onChange={(event) => { handleCheckboxChange(event) }} color='primary' />
                    }
                    label={t('auth.registerTenant.checkboxLabel')}
                  />
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox name="isOnboarding" onChange={(event) => { handleCheckboxChange(event) }} color='primary' />
                    }
                    label={t('auth.registerTenant.onboarding')}
                  />
                  {!addCurrent && (
                    <>
                      <InputFormField
                        name="email"
                        handleChange={handleChange}
                        error={values.email.error.length > 0}
                        value={values.email.value}
                        type="email"
                        placeholder={`${t('auth.emailLabel')} *`}
                      />
                      <PasswordFormField
                        name={"password"}
                        label={t('auth.resetPassword.passwordLabel')}
                        handleChange={handleChange}
                        handleClickShowPassword={handleClickShowPassword}
                        password={values.password.value}
                        error={values.password.error.length > 0}
                        showPassword={showPassword}
                      />
                      <PasswordFormField
                        name={"confirmPassword"}
                        label={t('auth.resetPassword.confirmPasswordLabel')}
                        handleChange={handleChange}
                        handleClickShowPassword={handleClickShowConfirmPassword}
                        password={values.confirmPassword.value}
                        error={values.confirmPassword.error.length > 0}
                        showPassword={showConfirmPassword}
                      />
                    </>
                  )}
                  <div className={classes.controlButtons}>
                    <Button className={clsx(classes.button, classes.cancelButton)} variant="contained" color='inherit' onClick={() => history.push('/')}>
                      {t('common.back')}
                    </Button>
                    <Button disabled={disable} className={classes.button} onClick={createTenant} type="button" variant="contained" color='secondary'>
                      {t('auth.registerTenant.buttonLabel')}
                    </Button>
                  </div>
                </form>
              </div>
            </Section>
          </RegisterMain>
        </Main>
      </>
    )
  );
};

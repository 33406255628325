import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Config } from 'config';
import { Errors } from 'const';
import { ApiError } from 'utils/api-error';
import { IAPIResponse } from 'interfaces/api-response.interface';
import qs from 'qs';

export class HttpClientService {
  constructor(
    private instance = axios.create({
      baseURL: Config.API_URL,
      paramsSerializer: (params: string | string[]) => qs.stringify(params, {arrayFormat: 'repeat'}),
    })
  ) {
    instance.interceptors.response.use(this.onResponse, this.onResponseError);
  }

  public setHeaderToken(token: string | null) {
    this.instance.defaults.headers['Authorization'] = token ? `Bearer ${token}` : undefined;
  }

  public setHeaderLanguage(language: string) {
    this.instance.defaults.headers['Accept-Language'] = language;
  }

  public request(config: AxiosRequestConfig) {
    return this.instance(config);
  }

  public get<R extends any, T = IAPIResponse<R>>(url: string, config?: AxiosRequestConfig) {
    return this.instance.get<T>(url, config)
  }

  public delete<R extends any>(url: string, config?: AxiosRequestConfig) {
    return this.instance.delete<IAPIResponse<R>>(url, config);
  }

  public post<T extends any, R = boolean>(url: string, payload: T, config?: AxiosRequestConfig) {
    return this.instance.post<IAPIResponse<R>>(url, payload, config);
  }

  public put<T extends any, R = boolean>(url: string, payload: T, config?: AxiosRequestConfig) {
    return this.instance.put<IAPIResponse<R>>(url, payload, config);
  }

  private onResponse(res: AxiosResponse<any>) {
    if (res.config.maxRedirects === 0) {
      window.location.href = res.request.responseURL
    }
    return res.data;
  }

  private onResponseError(err: AxiosError) {

    if (!err.code && !err.response) {
      return Promise.reject(new ApiError(Errors.NETWORK_ERROR, err));
    }

    if (err.response?.data.success === false) {
      return Promise.reject(new ApiError(Errors.RESPONSE_ERROR, err));
    }

    const statusCode = Number(err.response?.status);

    if (statusCode > 399 && statusCode < 500) {
      switch (statusCode) {
        case 401: {
          localStorage.clear();
          if (!window.location.pathname.includes('/auth')) {
            window.location.replace('/auth');
          }
        }
      }
      return Promise.reject(new ApiError(Errors.REQUEST_ERROR, err));
    }

    return Promise.reject(new ApiError(Errors.FATAL_ERROR, err));
  }
}

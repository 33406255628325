import React, { FormEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Config } from 'config';

import { makeStyles } from '@material-ui/core/styles';
import { Button, OutlinedInput, Typography, Box, InputAdornment, IconButton } from '@material-ui/core';

import { FormField } from 'modules/core/components/common';
import { AuthService } from 'modules/core/services';
import { useService } from 'modules/core/hooks';
import { _refButton } from 'styles/utils';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';
import { PersonIcon } from 'assets/svg';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  errorText: {
    ...theme.mixins.fonts.regular.xs,
    color: theme.palette.error.main,
    textAlign: 'center'
  },
  label: {
    marginTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '24px'
  },
  headline: {
    marginLeft: '8px',
    marginBottom: 'none'
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  rootInput: {
    width: '100%',
    maxWidth: '447px',
    alignSelf: 'center',
    paddingRight: 0
  },
  rootInputField: {
    marginBottom: theme.spacing(3)
  },
  rootInputFieldText: {
    ...theme.typography.body2,
    textAlign: 'center',
    margin: theme.spacing(1, 0, 3),
    color: theme.palette.secondary.dark
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  loginButton: {
    width: '100%',
    maxWidth: '448px'
  },
  refButton: {
    ..._refButton,
    fontFamily: '"niveau-grotesk", sans-serif',
    width: '100%',
    color: theme.palette.primary.main
  },
  externalAuthorization: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    margin: '24px 0'
  },
  inputAdornment: {
    position: 'absolute',
    right: '12px',
    top: '50%',
    paddingRight: 0
  }
}));


export const LoginCard = ({ authWithMicrosoft }: any) => {
  const classes = makeClasses();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const authService = useService<AuthService>(AuthService);
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [email, setEmail] = React.useState<string>('');
  const [emailFormatIsCorrect, setEmailFormatIsCorrect] = React.useState(true);
  const [password, setPassword] = React.useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [passwordFormatIsCorrect, setPasswordFormatIsCorrect] = React.useState(true);
  const [isAuthorizing, setIsAuthorizing] = React.useState(false);

  const authorize = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmailFormatIsCorrect(emailPattern.test(email) && !!email.length);
    setPasswordFormatIsCorrect(!!password.length);
    if (emailFormatIsCorrect && passwordFormatIsCorrect) {
      try {
        setIsAuthorizing(true);
        const hasBeenAuthorized = await authService.auth(email, password);
        localStorage.setItem(Config.STORED_ACCOUNT_TYPE, 'INTERNAL');
        if (typeof hasBeenAuthorized === 'object') {
          const { tenantTokens, token } = hasBeenAuthorized
          enqueueSnackbar(t('auth.loginSuccess'), {
            variant: 'success'
          });
          await authService.login(token);
          history.push({
            pathname: '/user/tenants',
            state: tenantTokens
          });
        } else {
          setIsAuthorizing(false);
          enqueueSnackbar(t('auth.wrongEmailOrPassword'), {
            variant: 'error'
          });
        }
      } catch (e) {
        enqueueSnackbar(t('auth.loginError'), {
          variant: 'error'
        });
        setIsAuthorizing(false);
      }
    }
  };

  const authorizeWithMicrosoft = (e: React.MouseEvent) => {
    e.preventDefault();
    localStorage.setItem(Config.STORED_ACCOUNT_TYPE, 'MS');
    authService.externalSigninRedirect();
  };

  return (
    <form className={classes.formRoot} onSubmit={authorize}
    >
      <Box
        className={classes.title}
      >
        <PersonIcon style={{ fontSize: '35px' }} />
        <Typography variant='h3' className={classes.headline}>{t('auth.loginTitle')}</Typography>
      </Box>
      <Typography variant='body2' style={{ marginBottom: '80px' }}>{t('auth.loginMessage')}</Typography>
      <Box className={classes.inputContainer}>
        <FormField className={classes.formField}>
          <OutlinedInput
            name="email"
            error={!emailFormatIsCorrect}
            placeholder={t('common.email')}
            className={classes.rootInput}
            value={email}
            onChange={e => setEmail(e.target.value)}

          />
          {!emailFormatIsCorrect && (
            <Typography classes={{ body2: classes.errorText }} variant="body2">
              {t('auth.wrongLogin')}
            </Typography>
          )}
        </FormField>

        <FormField className={classes.formField} >
          <OutlinedInput
            name="password"
            error={!passwordFormatIsCorrect}
            type={showPassword ? 'text' : 'password'}
            placeholder={t('common.password')}
            className={classes.rootInput}
            value={password}
            onChange={e => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position='end' className={classes.inputAdornment}>
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label="toggle password visibility"
                >
                  {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormField>
        <Link
          className={classes.rootInputFieldText}
          to={'/forgot-password'}
        >
          <span>{t('auth.forgotPasswordQuestion')} </span>

        </Link>

      </Box>

      <Button type="submit"
        className={classes.loginButton}
        variant="contained"
        color="secondary"
        disabled={isAuthorizing}
      >
        {t('auth.loginButtonText')}
      </Button>

      <div className={classes.externalAuthorization}>
        {authWithMicrosoft && (
          <button onClick={authorizeWithMicrosoft} className={classes.refButton}>
            {t('auth.loginWithMicrosoft')}
          </button>
        )}
      </div>
    </form>
  );
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedInput } from '@material-ui/core';
import Search from '@material-ui/icons/Search';

import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';
import { useTranslation } from 'react-i18next';

interface FilterProps {
  value: string;
  handleChange: (valeu: string) => void
}

type Props = React.PropsWithChildren<FilterProps>;

const makeClasses = makeStyles<DefaultTheme>((theme: DefaultTheme) => ({
  root: {
    padding: '0 0 10px',
    position: 'relative',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  icon: {
    position: 'absolute',
    left: '24px',
    top: '3px',
    zIndex: 10
  },
  input: {
    paddingLeft: '80px',
    background: theme.palette.grey[200],
    borderRadius: '30px',
    border: 'none',
  },
  rootInput: {
    width: '100%',
    height: 40,
  },
}));

export const FilterField: React.FC<Props> = (props: Props) => {
  const classes = makeClasses();
  const { t } = useTranslation();

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.keyCode === 8) {
      props.handleChange(event.currentTarget.value)
    }
  }

  return (
    <div className={classes.root}>
      <Search fontSize={'large'} className={classes.icon} />
      <OutlinedInput
        value={props.value}
        name={'filter'}
        onKeyDown={onKeyDown}
        onChange={(event) => props.handleChange(event.target.value)}
        type={'text'}
        placeholder={t('common.searchBy')}
        classes={{ root: classes.rootInput, input: classes.input }}
      />
    </div>
  );
};

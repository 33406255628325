import { IMappedProperty } from 'interfaces/core/mapped-property';

export interface IRole extends IMappedProperty { }
export interface IRolePlain {
  name: string
}
export enum ROLES {
  CREATE_TENANT = 'CreateTenant',
  REMOVE_TENANT = 'RemoveTenant',
  IMPERSONATION = 'Impersonation',
  TENANT_RESTORABLE = 'TenantRestorable',
  RESTORE_TENANT = 'RestoreTenant'
}

export const _resetButtonStyle = {
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  background: 'none',
  border: 'none',
  fontSize: 'inherit',
  color: 'inherit',
};

export const _refButton = {
  ..._resetButtonStyle,
  outline: 0,
  fontSize: '20px',
  textDecoration: 'underline',
};

import jwtDecode from 'jwt-decode';
import { Config, APIPaths } from 'config';
import { IToken } from 'interfaces/token.interface';
import { ITenant } from 'interfaces/authorized-user.interface';
import store from 'modules/core/store';
import { setShowLoader, setAuthToken, setUserProfile } from 'modules/core/store/actions';
import { debug } from 'utils/debug';
import { CoreService } from './core.service';
import { forceHttps, pageOpenedInIFrame } from 'utils/window-utils';
import { authTokenSelector,   } from '../store/selectors';


export class AuthService extends CoreService {
  public async bootstrap() {
    const storedToken = localStorage.getItem(Config.STORED_TOKEN_KEY);
    const validatedToken = this.isTokenValid(storedToken);
    if (storedToken && validatedToken) {
      try {
        this.setToken(storedToken);
      } catch (e) {
        // Cannot get user profile - so we should reset token
        this.logout();
        debug(e);
      }
    } else if (storedToken) {
      localStorage.removeItem(Config.STORED_TOKEN_KEY);
      localStorage.removeItem(Config.STORED_ACCOUNT_TYPE);
    }
    store.dispatch(setShowLoader(false));
  }

  public async auth(email: string, password: string) {
    try {
      const { value } = await this.httpClientService.post<{}, { tenantTokens: ITenant[], token: string }>(APIPaths.INTERNAL_LOGIN, {
        username: email,
        password
      });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  public login = async (token: string) => {
    this.setToken(token);
  };

  public async logout(withExternal = true) {
    store.dispatch(setUserProfile(false))
    this.setToken(null);
  }

  public externalSigninRedirect() {
    if (pageOpenedInIFrame()) {
      window.open(`${forceHttps(window.location.origin)}${APIPaths.EXTERNAL_LOGIN}`);
      window.location.assign('/auth-redirect');
    } else {
      window.location.assign(`${forceHttps(window.location.origin)}${APIPaths.EXTERNAL_LOGIN}`);
    }
  }
  // TODO: Commented for local development purposes
  // public externalSigninRedirect() {
  //   if (pageOpenedInIFrame()) {
  //     window.open(`${Config.API_URL}${APIPaths.EXTERNAL_LOGIN}`);
  //     window.location.assign('/auth-redirect');
  //   } else {
  //     window.location.assign(`${Config.API_URL}${APIPaths.EXTERNAL_LOGIN}`);
  //   }
  // }

  public externalSignOutRedirect() {
    if (pageOpenedInIFrame()) {
      window.open(`${forceHttps(window.location.origin)}${APIPaths.EXTERNAL_LOGOUT}`);
      window.location.assign('/auth-redirect');
    } else {
      window.location.assign(`${forceHttps(window.location.origin)}${APIPaths.EXTERNAL_LOGOUT}`);
    }
  }

  public setToken(authorizationToken: string | null) {
    if (authorizationToken) {
      localStorage.setItem(Config.STORED_TOKEN_KEY, authorizationToken);
    } else {
      localStorage.removeItem(Config.STORED_TOKEN_KEY);
      localStorage.removeItem(Config.STORED_ACCOUNT_TYPE);
    }
    this.httpClientService.setHeaderToken(authorizationToken);
    store.dispatch(setAuthToken(authorizationToken ?? ''));
  }

  public get isAuthorized() {
    const storeState = store.getState();
    return Boolean(authTokenSelector(storeState));
  }

  public isTokenValid(token = localStorage.getItem(Config.STORED_TOKEN_KEY)): IToken | false {
    if (token) {
      try {
        const validated = jwtDecode(token) as IToken;
        const { exp } = validated;
        const now = Math.floor(Date.now() / 1000);
        if (exp > now) {
          return validated;
        }
        return false;
      } catch (e) {
        return false;
      }
    }
    return false;
  }
  externalSignout = () => this.httpClientService.get(APIPaths.EXTERNAL_LOGOUT);
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export interface ISpinnerProps {
  text?: string;
}

const makeClasses = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '&::before, &::after, & > i': {
      content: '""',
      display: 'block',
      animation: `spinnerAnimation .6s ${theme.transitions.easing.sharp} infinite`,
      width: 13,
      height: 60,
      background: theme.palette.primary.main,
      margin: 3
    },
    '&::before': {
      animationDelay: ''
    },
    '& > i': {
      animationDelay: '.2s'
    },
    '&::after': {
      animationDelay: '.4s'
    }
  },
  bodyText: {}
}));

export const Spinner: React.FC<ISpinnerProps> = (props: ISpinnerProps) => {
  const classes = makeClasses();
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <i />
      </div>
      {props.text && <p className={classes.bodyText}>{props.text}</p>}
    </div>
  );
};

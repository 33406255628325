import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import en from './lang/defaults/en';
import pl from './lang/defaults/pl';
import lt from './lang/defaults/lt';
import lv from './lang/defaults/lv';
import sr from './lang/defaults/sr';
import sl from './lang/defaults/sl';
import sk from './lang/defaults/sk';
import cs from './lang/defaults/cs';
import ro from './lang/defaults/ro';
import hu from './lang/defaults/hu';
import de from './lang/defaults/de';
import es from './lang/defaults/es';
import hr from './lang/defaults/hr';
import it from './lang/defaults/it';
import bg from './lang/defaults/bg';
import et from './lang/defaults/et';

let lang = { coreLng: 'en', fallbackLng: ['en', 'pl', 'lt', 'lv', 'sr', 'sl', 'sk', 'cs', 'ro', 'hu', 'de', 'es', 'hr', 'it', 'bg', 'et'] };

i18n
  .use(intervalPlural)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources: {
      en,
      pl,
      lt,
      lv,
      sr,
      sl,
      sk,
      ro,
      cs,
      hu,
      de,
      es,
      hr,
      it,
      bg,
      et
    },
    detection: {
      order: ['navigator'],
    },
    // TODO: maybe handle this better (from config?) when project grows
    fallbackLng: { [lang.coreLng]: [...lang.fallbackLng],
      'default': ['en'] },
    debug: false,

    interpolation: {
      escapeValue: true // react already safes from xss
    }
  });

export default i18n;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BACKGROUND, resolveBackground } from 'utils/background-utils';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';

export interface ICardHeaderGradientProps {
  color?: BACKGROUND;
}

export type Props = React.PropsWithChildren<ICardHeaderGradientProps>;

const makeClasses = makeStyles<DefaultTheme, ICardHeaderGradientProps>(theme => ({
  root: {
    ...theme.mixins.fonts.regular.s,
    minHeight: 60,
    width: '100%',
    color: '#fff',
    display: 'inline-flex',
    padding: `0 ${theme.spacing(3)}px`,
    alignItems: 'center',
    background: props => resolveBackground(theme, props.color),
    borderRadius: theme.shape?.borderRadius,
    '& span, & svg': {
      display: 'inline',
      verticalAlign: 'middle'
    },
    '& svg': {
      width: 20,
      height: 'auto',
      marginRight: 20
    },
    '& span': {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 300
    }
  }
}));

export const CardHeaderGradient: React.FC<Props> = ({ color, children }) => {
  const classes = makeClasses({
    color
  });

  return <div className={classes.root}>{children}</div>;
};

CardHeaderGradient.defaultProps = {
  color: BACKGROUND.PRIMARY
};

import { combineReducers } from 'redux';

import * as CoreStoreReducer from './reducer';
import * as CoreStoreActions from './actions';


export type StoreActions = CoreStoreActions.Actions ;

export interface IStoreState {
  core: CoreStoreReducer.ICoreStoreState;
}

export const initialStoreState: IStoreState = {
  core: CoreStoreReducer.coreStoreInitialState,
};

export const reducers = combineReducers<IStoreState>({
  core: CoreStoreReducer.coreStoreReducer,
});

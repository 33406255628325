export enum APIPaths {
  USER = '/user',
  TENANTS = '/tenants',
  USER_TENANTS = '/tenant-users',
  REGISTER = '/accounts',
  INTERNAL_LOGIN = '/accounts/login-internal',
  EXTERNAL_LOGIN = '/accounts/login-external',
  EXTERNAL_LOGOUT = '/accounts/externalSignOut',
  PASSWORD_RESET = '/accounts/reset-password',
  PASSWORD_RESET_TOKEN = '/accounts/password-reset-token',
  CHANGE_PASSWORD = '/accounts/change-password',
}

import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  min-height: 100vh;
`

export const Headline = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`

export const Card = styled.div`
  margin-top: 60px;
  min-width: 400px;
  & > div:last-child {
    padding-bottom: 0;
    max-height: '545px';
    overflow-y: auto;
  }
`

export const CardContent = styled.div`
  position: relative;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  padding: 0;
  margin: 0 0 15px;
  list-style: none;
  cursor: pointer;
`;

export const ListLink = styled.a`
  padding: 0px 15px 0px 30px;
  margin: 0;
  display: block;
  list-style: none;
  border-radius: 50px;
  color: #333;
  background: #FFF;
  text-decoration: none;
  &:hover {
    background: rgba(0,0,0,0.1);
  }
`;

export const Title = styled.strong`
  display: block;
  font-size: 18px;
  min-height: 24px;
`;

export const SubTitle = styled.div`
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  color: darkgray;
`;

export const noAccessText = styled.p`
  text-align: center;
  color: #333;
  padding: 34px 20px;
`

export const FooterList = styled.footer`
  padding: 15px;
  overflow: hidden;
`
export const ButtonNav = styled(Button)`
  padding:  0 12px;
  height: 30px;
  margin: 0;
  float: right;
  list-style: none;
  color: #333;
  background: none;
  border: 0 none;
  text-decoration: none;
`;
import React from 'react';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { RootLoader } from 'modules/core/components/common';
import { useModules } from 'modules/core/hooks';
import { useGlobalStyles } from 'styles/global';



export const App = () => {
  const { Modules } = useModules();

  useGlobalStyles();

  return (
    <SnackbarProvider maxSnack={3}>
      <CssBaseline />
      <RootLoader />
      <Modules />
    </SnackbarProvider>
  );
};

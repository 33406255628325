import { APIPaths } from 'config';
import { CoreService } from 'modules/core/services/core.service';
import { ITenant, ITenantUserReponse, ITenantRedirectReponse, IGetPaginationFilter } from 'interfaces/authorized-user.interface';


export class TenantsService extends CoreService {
  getTenants = async (pagination: IGetPaginationFilter) => {
    try {
      const { value } = await this.httpClientService.get<ITenantRedirectReponse>('/users' + APIPaths.TENANTS, { params: pagination })
      return value
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  createTenant = async (name: string, addCurrentAccount: boolean, isOnboarding: boolean, email: string, password: string) => {
    try {
      const { value } = await this.httpClientService.post<{}, ITenant[]>(APIPaths.TENANTS, addCurrentAccount ? {
        name,
        addCurrentAccount,
        isOnboarding,
      } : {
        name,
        addCurrentAccount,
        email,
        password,
        isOnboarding,
      }, { maxRedirects: 0 })
      return value
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  restoreTenant = (id: string) => {
    return this.httpClientService
      .post(`tenants/${id}/restore`, {})
      .catch(this.appService.handleError);
  }

  getTenantToken = (idTenant: string, idUser?: string) => {

    const URL =  `${APIPaths.TENANTS}/${idTenant}${idUser ? '/' + idUser : ''}`
    return this.httpClientService
      .get<ITenant[]>(URL, { maxRedirects: 0 })
      .catch(this.appService.handleError);
  }

  getTenantUsers = (id: string, pagination: IGetPaginationFilter) => {
    return this.httpClientService
      .get<ITenantUserReponse>(`tenant/${id}/users`, { params: pagination })
      .catch(this.appService.handleError);

  }

}
import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { FormField } from 'modules/core/components/common';

const SelectLabel = styled.label`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 15px;
  font-family: 'niveau-grotesk', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.00938em;
`;

const makeClasses = makeStyles(theme => ({
  rootInput: {
    width: '100%'
  },
  select: {
    height: '40px'
  },
  selectLabel: {
    font: 'Regular 15px/24px niveau-grotesk'
  }
}));

export const SelectFormField = ({ name, label, handleChange, value, options, required }: any) => {
  const classes = makeClasses();

  return (
    <FormField>
      <FormControl variant="outlined" className={classes.rootInput}>
        <SelectLabel className={classes.selectLabel} htmlFor={'select-' + { name }}>
          {label} {required && '*'}
        </SelectLabel>
        <Select
          className={classes.select}
          labelId={'select-' + { name } + '-label'}
          id={'select-' + { name }}
          value={value}
          onChange={handleChange(name)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((option: any, i: any) => {
            return (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </FormField>
  );
};

import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { UsersService } from './users.service';
import { LangService } from './lang.service';
import { TenantsService } from './tenants.service';

export {
  AppService,
  AuthService,
  UsersService,
  LangService,
  TenantsService
};

export type Services =
  | AppService
  | AuthService
  | UsersService
  | LangService
  | TenantsService


const context = new Map(); // keeps instances of services

export const getService = <T extends any>(Service: new () => T): T => {
  if (context.has(Service)) {
    return context.get(Service);
  }
  const service = new Service();
  context.set(Service, service);
  return service;
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Container } from '@material-ui/core';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const makeClasses = makeStyles<DefaultTheme, { scrolled: boolean }>((theme: DefaultTheme) => ({
  appbarRoot: {
    zIndex: theme.zIndex.drawer + 1,
    background: (props) => props.scrolled ? 'white' : 'none'
  },
  toolbarRoot: {
    justifyContent: 'space-between',
    textAlign: 'end',
    minHeight: 120,
    [theme.breakpoints.down('md')]: {
      minHeight: 100
    }
  }
}));

export interface IAppbarProps { }

type Props = React.PropsWithChildren<IAppbarProps>;

export const Appbar: React.FC<Props> = (props: Props) => {
  const scrolltrigger = useScrollTrigger({
    threshold: 80
  })
  const classes = makeClasses({
    scrolled: scrolltrigger
  });

  return (
    <AppBar color="default" elevation={0} classes={{ root: classes.appbarRoot }}>
      <Container maxWidth={'lg'}>
        <Toolbar classes={{ root: classes.toolbarRoot }}>{props.children}</Toolbar>
      </Container>
    </AppBar>
  );
};

import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore } from 'redux';
import { reducers, initialStoreState, IStoreState, StoreActions } from './store';

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

const store = IS_DEVELOPMENT
  ? createStore<IStoreState, StoreActions, any, any>(reducers, initialStoreState, composeWithDevTools())
  : createStore(reducers, initialStoreState);
export default store;

import React from 'react';
import { ExternalLoginView } from 'modules/core/views/auth/components/external-login-view';
import { ExternalLoginLink } from 'modules/core/views/auth/components/external-login-link';
import { useTranslation } from 'react-i18next';

export const ExternalLoginError: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ExternalLoginView
      title={t('auth.externalLogin.titleError')}
    >
      {`${t('auth.externalLogin.descriptionErrorTop')}`}
      <br />
      {`${t('auth.externalLogin.descriptionErrorBottom')} `}
      <ExternalLoginLink>
        {t('auth.externalLogin.loginLinkError')}
      </ExternalLoginLink>
    </ExternalLoginView>
  );
};

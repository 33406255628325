import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';

export interface ICardTitleProps {
  className?: string;
}
export type Props = React.PropsWithChildren<ICardTitleProps>;

const makeClasses = makeStyles(theme => ({
  root: {
    color: '#000',
    fontWeight: 500,
    lineHeight: 1,
    fontSize: 16,
    '& svg': {
      verticalAlign: 'middle',
      marginRight: 5
    }
  }
}));

export const CardTitle: React.FC<Props> = (props: Props) => {
  const classes = makeClasses();
  return (
    <Typography variant="body2" className={clsx(classes.root, props.className)}>
      {props.children}
    </Typography>
  );
};

import React from 'react';
import { MultiSelectSearchBar } from './multiselect-searchbar';
import { MultiSelectPopoverHeadType } from './interfaces';

export const MultiSelectPopoverHead: MultiSelectPopoverHeadType = props => {
  const { Before, After, options } = props;

  return (
    <>
      {Before ? Before : null}
      {options?.searchBar && (
        <MultiSelectSearchBar
          searchbarValue={props.filterValue}
          onSearchBarValueChange={e => props.setFilterValue(e.target.value)}
        />
      )}
      {After ? After : null}
    </>
  );
};

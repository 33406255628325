import { AxiosError, AxiosResponse } from 'axios';
import { Errors } from 'const/errors';
import { IAPIResponse } from 'interfaces/api-response.interface';
import { IApiErrorMessage } from 'interfaces/api-error-message.interface';
import { MyError } from 'utils/error';

export class ApiError extends MyError<AxiosResponse<IAPIResponse>> {
  constructor(public type: Errors, public error: AxiosError<IAPIResponse>) {
    super(type, error.response);
  }

  get errors(): IApiErrorMessage[] {
    return this.payload?.data?.errors || [];
  }

  get messages(): string[] {
    return this.errors.map(error => error.message);
  }
}

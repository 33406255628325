import { Errors } from 'const';
import { IUserProfile } from 'interfaces/user.interface';

export enum CoreStoreActions {
  SET_SHOW_LOADER = 'SET_SHOW_LOADER',
  SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
  SET_ERROR_TYPE = 'SET_ERROR_TYPE',
  SET_CORE_SERVICES_READY = 'SET_CORE_SERVICES_READY',
  SET_USER_PROFILE = 'SET_USER_PROFILE',
  SET_CURRENT_TENANT = 'SET_CURRENT_TENANT'
}

export interface ISetCoreServicesReady {
  type: CoreStoreActions.SET_CORE_SERVICES_READY,
  payload: {
    statement: boolean,
  }
}

export const setCoreServicesReady = (statement: boolean) => ({
  type: CoreStoreActions.SET_CORE_SERVICES_READY,
  payload: {
    statement
  }
});

export interface ISetCoreError {
  type: CoreStoreActions.SET_ERROR_TYPE,
  payload: {
    error: {
      type: Errors;
      message?: string;
    } | null
  }
}

export const setCoreError = (error: Error | null, message?: string) => {
  if (error) {
    return {
      type: CoreStoreActions.SET_ERROR_TYPE,
      payload: {
        type: error,
        message,
      }
    }
  }
  return {
    type: CoreStoreActions.SET_ERROR_TYPE,
    payload: {
      error: null,
    }
  }
};

export interface ISetShowLoaderAction {
  type: CoreStoreActions.SET_SHOW_LOADER;
  payload: {
    statement: boolean;
  };
}

export const setShowLoader = (statement: boolean) => ({
  type: CoreStoreActions.SET_SHOW_LOADER,
  payload: {
    statement
  }
});

export interface ISetCurrentTenantAction {
  type: CoreStoreActions.SET_CURRENT_TENANT;
  payload: {
    tenantId: string;
  };
}

export const setCurrentToken = (tenantId: string) => ({
  type: CoreStoreActions.SET_CURRENT_TENANT,
  payload: {
    tenantId
  }
});

export interface ISetAuthTokenAction {
  type: CoreStoreActions.SET_AUTH_TOKEN;
  payload: {
    token: string;
  };
}

export const setAuthToken = (token: string) => ({
  type: CoreStoreActions.SET_AUTH_TOKEN,
  payload: {
    token
  }
});

export interface ISetUserProfile {
  type: CoreStoreActions.SET_USER_PROFILE;
  payload: {
    profile: {
      created: string;
      email: string;
      id: string;
      language: string;
      roles: string[];
      loginProviders: string[];
    }
  };
}

export const setUserProfile = (profile: IUserProfile | false) => ({
  type: CoreStoreActions.SET_USER_PROFILE,
  payload: {
    profile
  }
});

export type Actions = ISetShowLoaderAction &
  ISetCoreServicesReady &
  ISetCoreError &
  ISetAuthTokenAction &
  ISetCurrentTenantAction &
  ISetUserProfile;

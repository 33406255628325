import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogProps, IconButton, useMediaQuery } from '@material-ui/core';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';
import { defaultTheme } from 'themes/default-theme';
import { Close } from '@material-ui/icons';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import clsx from 'clsx';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    fill: theme.palette.common.black
  }
}));

export interface ResponsiveModalProps extends DialogProps {
  className?: string;
  closeButton?: boolean;
  fullScreenBreakpoint?: Breakpoint;
}

export const ResponsiveModal: React.FC<ResponsiveModalProps> = props => {
  const { className, children, closeButton, fullScreenBreakpoint, ...rest } = props;
  const classes = makeClasses();
  const fullScreen = useMediaQuery(defaultTheme.breakpoints.down(fullScreenBreakpoint || 'xs'));
  return (
    <Dialog closeAfterTransition fullScreen={fullScreen} {...rest} className={clsx(classes.root, className)}>
      {children}
      {closeButton && (
        <IconButton
          className={classes.closeButton}
          onClick={event => {
            if (props.onClose) {
              props.onClose(event, 'backdropClick');
            }
          }}
        >
          <Close />
        </IconButton>
      )}
    </Dialog>
  );
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  footer: {
    position: 'relative',
    display: 'flex',
    marginTop: 'auto',
    maxWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid rgba(255, 255, 255, 0.5)',
    background: 'transparent',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10.5),

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(5)
    }
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: 'underline',
    cursor: 'pointer',

    '&:not(:first-child)': {
      marginLeft: theme.spacing(5)
    }
  }
}));

interface FooterComponentProps {}

export const Footer: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const classes = makeClasses();

  return (
    <footer className={classes.footer}>{props.children}</footer>
  );
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import { MultiSelectListRow } from './multiselect-list-row';
import { IMultiSelectListProps, MultiSelectListComponentType } from './interfaces';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme, IMultiSelectListProps>(theme => ({
  listRoot: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    overflow: 'auto'
  },

  listOverflowRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: props => props.options && props.options.fullListHeight ? theme.spacing(32) : 'auto',
    maxHeight: theme.spacing(32),
  },

  footerRoot: {
    marginTop: 'auto',
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(2)}px`
  }
}));

export const MultiSelectList: MultiSelectListComponentType = props => {
  const { Footer } = props;
  const classes = makeClasses(props);

  return (
    <div className={clsx(props.className || classes.listOverflowRoot, props.classNames?.listContainerRoot)}>
      <List dense disablePadding={true} classes={{ root: clsx(classes.listRoot, props.classNames?.listRoot) }} >
        {
          props.values.map(value => (
            <MultiSelectListRow
              key={value.id}
              row={value}
              checked={props.selectedValues.some(selected => selected.id === value.id)}
              onSelect={props.onSelect}
              selectedValues={props.selectedValues}
              options={props.options}
            />
          ))
        }
      </List>
      {Footer && <div className={clsx(classes.footerRoot, props.classNames?.footerRoot)}>{Footer}</div>}
    </div>
  );
};

import { APIPaths } from 'config';
import { CoreService } from 'modules/core/services/core.service';
import { IUserProfile } from 'interfaces/user.interface';

export class UsersService extends CoreService {
  getUserProfile = () =>
    this.httpClientService
      .get<IUserProfile>('/users/profile')
      .catch(this.appService.handleError);

  resetUserPassword = async (email: string) => {
    try {
      const { value } = await this.httpClientService.post<{}, { token: string }>(APIPaths.PASSWORD_RESET_TOKEN, { email })
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  resetPassword = (password: string, token: string) =>
    this.httpClientService
      .post(`${APIPaths.PASSWORD_RESET}`, {
        password,
        token
      })
      .catch(this.appService.handleError);

  changePassword = (oldPassword: string, newPassword: string) =>
    this.httpClientService
      .post(`${APIPaths.CHANGE_PASSWORD}`, {
        oldPassword,
        newPassword
      })
      .catch(this.appService.handleError);

  registerUser = (email: string, name: string, surname: string) =>
    this.httpClientService
      .post(`${APIPaths.CHANGE_PASSWORD}`, {
        email,
        name,
        surname
      })
      .catch(this.appService.handleError);


  updateUserLanguage = async (languageId: string) => this.httpClientService.post(`/users/language`, {
    languageId
  }).catch(this.appService.handleError);

  fetchUserProfile = async () => {
    try {
      const { value: profile } = await this.httpClientService.get<IUserProfile>(`/users/profile/`);
      return profile;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };
}

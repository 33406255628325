import React from 'react';
import { Section } from 'modules/core/components/layout';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { defaultTheme } from 'themes/default-theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { coreErrorSelector } from 'modules/core/store/selectors';
import { Errors } from 'const';

const makeClasses = makeStyles<Theme>((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  box: {
    maxWidth: '500px',
    padding: '40px',
    margin: '0 20px',
    background: defaultTheme.palette.common.white,
    boxShadow: '0 31px 53px rgba(0, 0, 0, 0.3), inset 0px 0px 0px 1px #E4E4E4',
    borderRadius: defaultTheme.shape.borderRadius
  },
  headline: {
    ...defaultTheme.mixins.fonts.regular.l,
    margin: '0 0 20px'
  },

  link: {
      fontWeight: 'bold',
      color: defaultTheme.palette.secondary.main
  }
}));

export interface IErrorViewProps {
  error?: Errors,
}

export const ErrorView: React.FC<IErrorViewProps> = (props) => {
  const classes = makeClasses();
  const { t } = useTranslation();

  const {
    error: receivedError
  } = props;

  const coreError = useSelector(coreErrorSelector);

  const error = React.useMemo(
    () => {

      if (receivedError) {
        return {
          type: receivedError,
        }
      }

      if (coreError) {
        return coreError;
      }

      return {
        type: Errors.PAGE_NOT_FOUND
      };

    },
    [receivedError, coreError]
  );

  return (<>
    <Section >
      <div className={classes.wrapper}>
        <div className={classes.box}>
          <h2 className={classes.headline}>{t(`errorPages.${error.type}.headline`)}</h2>
          <p>{t(`errorPages.${error.type}.text`)}</p>
          <Link to="/" className={classes.link}>{t(`errorPages.${error.type}.link`)}</Link>
        </div>
      </div>
    </Section>
  </>);
};
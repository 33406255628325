import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { userProfileSelector, currentTenantIdSelector } from 'modules/core/store/selectors';
import { LoaderView } from '..';
import { Main } from 'modules/core/components/layout';
import { FilterField } from 'modules/core/components/common/filter/filter'
import { Navigation } from 'modules/core/components/layout/nav';
import { IGetPaginationFilter, ITenantUser } from 'interfaces/authorized-user.interface';
import { useService } from 'modules/core/hooks';
import { UsersService, AuthService, TenantsService } from 'modules/core/services';
import { maxRecords, showApiErrors } from 'utils';
import { setUserProfile } from 'modules/core/store/actions';
import store from 'modules/core/store';
import * as S from './styled'
import { ApiError } from 'utils/api-error';
import { Box, Typography } from '@material-ui/core';
import { PersonIcon } from 'assets/svg';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '24px'
  },
  headline: {
    marginLeft: '8px',
    marginBottom: 'none'
  },
}));

export const SelectionTenentUsers = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenantsService = useService<TenantsService>(TenantsService);
  const usersService = useService(UsersService);
  const userProfile = useSelector(userProfileSelector);
  const currentTenantId = useSelector(currentTenantIdSelector);
  const authService = useService(AuthService);
  const classes = makeClasses();

  const [tenantUsers, setTenantUsers] = useState<ITenantUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState<IGetPaginationFilter>({
    size: 5,
    page: 1
  });
  const [maxPage, setMaxPage] = useState<number>();
  const [filterValue, setFilterValue] = useState('');
  const [filterDebounceValue, setFilterDebounceValue] = useState<string | undefined>(undefined);

  useEffect(
    () => {
      if (!userProfile) {
        getProfile()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )
  const getProfile = async () => {
    try {
      const { value } = await usersService.getUserProfile()
      store.dispatch(setUserProfile(value));
    } catch (e) {
      authService.appService.handleError(e);
    }
  }
  const loadTenants = async () => {
    if (userProfile && currentTenantId) {
      try {
        setLoading(true);
        const { value: { records, recordsCount } } = await tenantsService.getTenantUsers(currentTenantId, pagination);
        if (pagination.size) {
          const maxPageValue = maxRecords(recordsCount, pagination.size);
          setMaxPage(maxPageValue);
        }
        setTenantUsers(records);
        setLoaded(true);
      } catch (e) {
        setError(true);
        showApiErrors(e as any, enqueueSnackbar);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (userProfile && currentTenantId) {
      loadTenants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  useEffect(() => {
    if (filterDebounceValue !== undefined) {
      setPagination({
        ...pagination,
        page: 1,
        Filter: filterDebounceValue
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDebounceValue])

  useEffect(() => {
    if (filterValue.length === 0 && tenantUsers.length === 0 && currentTenantId && userProfile && loaded) {
      tenantsService.getTenantToken(currentTenantId, userProfile.id);
    } else {
      if (tenantUsers.length > 0) {
        setLoadingPage(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  const userList = useMemo(() =>
    <S.List>
      {userProfile &&
        <S.ListItem key={userProfile.id}>
          <S.ListLink style={{ borderRadius: 0 }} onClick={(e) => { onSelect(e, userProfile) }}>
            <S.Title>Twoje konto</S.Title><S.SubTitle>{userProfile.email}</S.SubTitle>
          </S.ListLink>
        </S.ListItem>}
      {tenantUsers.length !== 0 || error ? tenantUsers.map((userTenant: ITenantUser) => (
        <S.ListItem key={userTenant.id}>
          <S.ListLink style={{ borderRadius: 0 }} onClick={(e) => { onSelect(e, userTenant) }}><S.Title>{userTenant.name}</S.Title><S.SubTitle>{userTenant.email}</S.SubTitle></S.ListLink>
        </S.ListItem>
      )) : (
        <S.noAccessText>{t('auth.tenantUserSelection.noAccess')}</S.noAccessText>
      )}
    </S.List>,
    [userProfile, tenantUsers])

  const onSelect = async (e: React.MouseEvent<HTMLAnchorElement>, userTenant: ITenantUser) => {
    try {
      setLoading(true)
      setLoadingPage(true);
      if (currentTenantId) {
        await tenantsService.getTenantToken(currentTenantId, userTenant.id);
        e.preventDefault();
      }
    } catch (e) {
      setError(true);
      showApiErrors(e as any, enqueueSnackbar);
    } finally {
      setLoading(false)
      setLoadingPage(false);
    }

  }

  useEffect(
    () => {
      const handler = setTimeout(() => {
        if (filterValue !== filterDebounceValue && !loading) {
          setFilterDebounceValue(filterValue);
        }
      }, 500);
      return () => {
        clearTimeout(handler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterValue]
  );

  const onFilter = (value: string) => {
    setFilterValue(value);
  }

  const onNext = () => {
    if (pagination.page) {
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const onPrev = () => {
    if (pagination.page) {
      setPagination({ ...pagination, page: pagination.page - 1 });
    }
  }

  return (
    loadingPage ? (
      <LoaderView background="#FFF" visible text={t('auth.loading')} />
    ) : (
      <>
        <Navigation />
        <Main withHeader withFooter withGradient>
          <S.Wrapper>
            <S.Card>
              <Box
                className={classes.title}
              >
                <PersonIcon style={{ fontSize: '35px' }} />
                <Typography variant='h3' className={classes.headline}>{t('auth.tenantUserSelection.title')}</Typography>
              </Box>
              <FilterField value={filterValue} handleChange={onFilter} />
              <S.CardContent>
                {loading && <LoaderView visible background="#FFF" position="absolute" />}
                {userList}
              </S.CardContent>
              <S.FooterList>
                {(pagination.page !== maxPage && (maxPage && 1 < maxPage)) ? <S.ButtonNav onClick={() => onNext()}>{t('common.next')}</S.ButtonNav> : ''}
                {(pagination.page && pagination.page > 1) && <S.ButtonNav onClick={() => onPrev()}>{t('common.prev')}</S.ButtonNav>}
              </S.FooterList>
            </S.Card>
          </S.Wrapper>
        </Main>
      </>
    )
  );
};

import React from 'react';
import { forceHttps, pageOpenedInIFrame } from 'utils/window-utils';

export interface ExternalLoginLinkProps {
  className?: string;
}

export const ExternalLoginLink: React.FC<React.PropsWithChildren<ExternalLoginLinkProps>> = (props) => {
  const linkProps = pageOpenedInIFrame()
    ? {
        rel: "noopener noreferrer",
      }
    : {};
  return (
    <a
      className={props.className}
      href={forceHttps(window.location.origin) + '/accounts/external'}
      {...linkProps}
    >
      {props.children}
    </a>
  );
};

import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useService } from 'modules/core/hooks';
import { useSnackbar } from 'notistack';
import { AuthService, TenantsService } from 'modules/core/services';
import store from 'modules/core/store';
import { setCurrentToken } from 'modules/core/store/actions';
import { userProfileSelector } from 'modules/core/store/selectors';
import { LoaderView } from '..';
import { FilterField } from 'modules/core/components/common/filter/filter';
import { Main } from 'modules/core/components/layout';
import { Navigation } from 'modules/core/components/layout/nav';
import { IGetPaginationFilter, ITenant } from 'interfaces/authorized-user.interface';
import { ROLES } from 'interfaces/role.interface';
import { maxRecords, showApiErrors } from 'utils';
import * as S from './styled';
import { APIPaths, Config } from 'config';
import { Box, Typography } from '@material-ui/core';
import { PersonIcon } from 'assets/svg';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '24px'
  },
  headline: {
    marginLeft: '8px',
    marginBottom: 'none'
  },
}));

export const SelectionTenents = (props: any) => {
  const history = useHistory();
  const searchParams = useSearchParams();
  const classes = makeClasses();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const userProfile = useSelector(userProfileSelector);
  const tenantsService = useService<TenantsService>(TenantsService);
  const authService = useService(AuthService);

  const [tenants, setTenants] = useState([] as ITenant[]);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [isUsers, setIsUsers] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [filterDebounceValue, setFilterDebounceValue] = useState<string | undefined>();
  const [error, setError] = useState(false);
  const [maxPage, setMaxPage] = useState<number>();
  const [pagination, setPagination] = useState<IGetPaginationFilter>({
    size: 5,
    page: 1
  });
  const isRestorableUser = useMemo(() => userProfile && userProfile?.roles.includes(ROLES.RESTORE_TENANT), [userProfile]);
  const isSingleTenant = useMemo(() => tenants.length === 1 && pagination.page === 1 && (pagination.Filter === '' || pagination.Filter === undefined), [tenants, pagination]);
  const isMSLogout = useMemo(() => localStorage.getItem(Config.STORED_ACCOUNT_TYPE) === 'MS' && searchParams.get('logout'), [searchParams]);
  const isForceLogout = useMemo(() => searchParams.get('logout'), [searchParams])

  const loadTenants = async () => {
    setLoading(true)
    try {
      const { redirect, tenants: { records, recordsCount } } = await tenantsService.getTenants(pagination);
      if (pagination.size) {
        const valueSize = maxRecords(recordsCount, pagination.size);
        const maxPageValue = recordsCount % pagination.size === 0 ? valueSize : valueSize + 1;
        setMaxPage(maxPageValue);
      }
      setTenants(() => {
        setIsUsers(redirect !== null);
        return records;
      });
    } catch (e) {
      setError(false)
      showApiErrors(e as any, enqueueSnackbar);
    } finally {
      setLoading(false);
    }
  }

  const onRestoreTenant = async (id: string) => {
    try {
      setLoading(true);
      setRestoring(true)
      await tenantsService.restoreTenant(id);
    } catch (e) {
      setError(false)
      showApiErrors(e as any, enqueueSnackbar);
    } finally {
      setLoading(false);
      setRestoring(false)
    }
  }

  useEffect(
    () => {
      const handler = setTimeout(() => {
        if (!loading)
          setFilterDebounceValue(filterValue);
      }, 500);
      return () => {
        clearTimeout(handler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterValue]
  );

  useEffect(() => {
    if (isSingleTenant) {
      if (isMSLogout) {
        setLoadingPage(false);
      } else if (isForceLogout) {
        authService.logout();
      } else {
        const { id } = tenants && tenants[0];
        if (isUsers) {
          history.push({
            pathname: `${APIPaths.USER}${APIPaths.USER_TENANTS}/${id}`
          });
          store.dispatch(setCurrentToken(id));
        } else {
          tenantsService.getTenantToken(id);
        }
      }
    } else {
      if (tenants.length > 1) {
        setLoadingPage(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);

  useEffect(() => {
    if (userProfile) {
      loadTenants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    if (filterDebounceValue !== undefined) {
      setPagination({
        ...pagination,
        page: 1,
        Filter: filterDebounceValue
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDebounceValue]);

  const onSelect = async (e: React.MouseEvent<HTMLAnchorElement>, tenant: ITenant) => {
    setLoading(true)
    setCurrentToken(tenant.id)
    tenantsService.getTenantToken(tenant.id)
    e.preventDefault();
  }

  const onSelectRedirect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tenant: ITenant) => {
    store.dispatch(setCurrentToken(tenant.id));
    history.push(`${APIPaths.USER}${APIPaths.USER_TENANTS}/${tenant.id}`);
  }

  const onFilter = (value: string) => {
    setFilterValue(value);
  }

  const onNext = () => {
    const { page } = pagination;
    if (page) {
      setPagination({ ...pagination, page: page + 1 });
    }
  }

  const onPrev = () => {
    const { page } = pagination;
    if (page) {
      setPagination({ ...pagination, page: page - 1 });
    }
  }
  return (
    loadingPage ? (
      <LoaderView background="#FFF" visible text={t('auth.loading')} />
    ) : (
      <>
        <Navigation />
        <Main withHeader withFooter withGradient>
          <S.Wrapper >
            <S.Card>
              <Box
                className={classes.title}
              >
                <PersonIcon style={{ fontSize: '35px' }} />
                <Typography variant='h3' className={classes.headline}>{t('auth.tenantSelection.pickTenant')}</Typography>
              </Box>
              <Typography variant='body2' style={{ textAlign: 'center' }} >{t('auth.tenantSelection.title')}</Typography>
              <FilterField value={filterValue} handleChange={onFilter} />
              <S.CardContent>
                {loading && <LoaderView visible background="#FFF" text={restoring ? t('auth.tenantSelection.restoreLoading') : undefined} position="absolute" />}
                <S.List>
                  {tenants.length !== 0 || error ? tenants.map((tenant: ITenant) => (
                    <S.ListItem key={tenant.id} >
                      {isUsers ? (
                        <>
                          <S.ListLink style={{ borderRadius: 0 }} onClick={((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onSelectRedirect(e, tenant))}>
                            <S.ListImageWrapper><S.ListImage logoUrl={tenant.logoUrl} logoAlt={tenant.name} /></S.ListImageWrapper>
                            <S.Title>{tenant.name}</S.Title>
                          </S.ListLink>
                          {isRestorableUser && tenant.isRestorable && (<S.ButtonRestorable onClick={() => onRestoreTenant(tenant.id)}>{t('auth.tenantSelection.restoreButton')}</S.ButtonRestorable>)}
                        </>
                      ) : (
                        <>
                          <S.ListHref style={{ borderRadius: 0 }} onClick={(e) => { onSelect(e, tenant) }} href={tenant.url}>
                            <S.ListImageWrapper><S.ListImage logoUrl={tenant.logoUrl} logoAlt={tenant.name} /></S.ListImageWrapper>
                            <S.Title>{tenant.name}</S.Title>
                          </S.ListHref>
                          {isRestorableUser && tenant.isRestorable && (<S.ButtonRestorable onClick={() => onRestoreTenant(tenant.id)}>{t('auth.tenantSelection.restoreButton')}</S.ButtonRestorable>)}
                        </>
                      )}
                    </S.ListItem>
                  )) : (
                    <S.noAccessText>{t('auth.tenantSelection.noAccess')}</S.noAccessText>
                  )}
                </S.List>
              </S.CardContent>
              <S.FooterList>
                {(pagination.page !== maxPage && (maxPage && 1 < maxPage)) ? <S.ButtonNav onClick={() => onNext()}>{t('common.next')}</S.ButtonNav> : ''}
                {(pagination.page && pagination.page > 1) && <S.ButtonNav onClick={() => onPrev()}>{t('common.prev')}</S.ButtonNav>}
              </S.FooterList>
            </S.Card>
          </S.Wrapper>
        </Main>
      </>
    )
  )
};

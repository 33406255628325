import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react'

export const LockIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width='41' height='40' viewBox="0 0 41 40" >
    <path fillRule="evenodd" clipRule="evenodd" d="M17.582 26.6667C17.582 25.0558 18.8879 23.75 20.4987 23.75C22.1095 23.75 23.4154 25.0558 23.4154 26.6667C23.4154 28.2775 22.1095 29.5833 20.4987 29.5833C18.8879 29.5833 17.582 28.2775 17.582 26.6667ZM20.4987 26.25C20.2686 26.25 20.082 26.4365 20.082 26.6667C20.082 26.8968 20.2686 27.0833 20.4987 27.0833C20.7288 27.0833 20.9154 26.8968 20.9154 26.6667C20.9154 26.4365 20.7288 26.25 20.4987 26.25Z" fill="#111826" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.582 17.0833V11.6667C12.582 9.56703 13.4161 7.5534 14.9008 6.06874C16.3854 4.58407 18.3991 3.75 20.4987 3.75C22.5983 3.75 24.612 4.58407 26.0966 6.06874C27.5813 7.5534 28.4154 9.56703 28.4154 11.6667V17.0833H28.832C31.3633 17.0833 33.4154 19.1354 33.4154 21.6667V31.6667C33.4154 34.198 31.3633 36.25 28.832 36.25H12.1654C9.63406 36.25 7.58203 34.198 7.58203 31.6667V21.6667C7.58203 19.1354 9.63406 17.0833 12.1654 17.0833H12.582ZM16.6685 7.8365C17.6844 6.82068 19.0621 6.25 20.4987 6.25C21.9353 6.25 23.313 6.82068 24.3289 7.8365C25.3447 8.85233 25.9154 10.2301 25.9154 11.6667V17.0833H15.082V11.6667C15.082 10.2301 15.6527 8.85233 16.6685 7.8365ZM12.1654 19.5833C11.0148 19.5833 10.082 20.5161 10.082 21.6667V31.6667C10.082 32.8173 11.0148 33.75 12.1654 33.75H28.832C29.9826 33.75 30.9154 32.8173 30.9154 31.6667V21.6667C30.9154 20.5161 29.9826 19.5833 28.832 19.5833H12.1654Z" fill="#111826" />
  </SvgIcon>
)


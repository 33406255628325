import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme, { onClickEnabled: boolean }>(theme => ({
  navBrand: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    cursor: props => (props.onClickEnabled ? 'pointer' : 'default'),

    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
      marginRight: 0
    }
  },
  navBrandImg: {
    [theme.breakpoints.down('lg')]: {
      height: 60
    },
    [theme.breakpoints.down('md')]: {
      height: 60
    }
  },
}));
export interface INavBrandProps {
  before?: React.ComponentType;
  after?: React.ComponentType;
  onClick?: () => void;
}

export type Props = INavBrandProps;

export const Logo: React.FC<Props> = (props: Props) => {
  const { before: Before, after: After, onClick } = props;
  const classes = makeClasses({ onClickEnabled: !!onClick });

  return (
    <div className={classes.navBrand} onClick={() => {
      if (onClick) {
        onClick();
      }
    }}>
      {Before && <Before/>}
      <img
              src={'/static/img/gu_logo.svg'}
              alt={'GrowUperion - Grow visibly taller'}
              className={classes.navBrandImg}
            />
      {After && <After/>}
    </div>
  );
};

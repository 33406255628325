import store from 'modules/core/store';
import { setCoreError } from 'modules/core/store/actions';
import { MyError } from 'utils/error';
import { Errors } from 'const';

export class AppService {
  handleError(error: MyError | any) {
    if (error instanceof MyError) {
      switch (error.type) {
        case Errors.NETWORK_ERROR:
          store.dispatch(setCoreError(error));
          break;
        case Errors.FATAL_ERROR:
          store.dispatch(setCoreError(error));
          break;
      }
      return Promise.reject(error);
    }
    throw error;
  }
}

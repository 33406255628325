import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { InputFormField, PasswordFormField } from 'modules/core/components/common';
import {  Main  } from 'modules/core/components/layout';
import { useSearchParams } from 'modules/core/hooks';
import { getService, UsersService } from 'modules/core/services';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';
import { showApiErrors } from 'utils/show-errors';
import { Navigation } from 'modules/core/components/layout/nav';
import { useTranslation } from 'react-i18next';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  container: {
    position: 'relative',
    maxWidth: '400px',
    margin: '15vh auto 0',
    padding: theme.spacing(3),
  },
  card: {
    maxWidth: 380,
    width: '100%',
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1)
  },
  controlButtons: {
    display: 'flex',
    padding: theme.spacing(1, 0),
  },
  button: {
    ...theme.mixins.fonts.bold.xs,
    marginTop: theme.spacing(2),
    width: '100%',
  }
}));

interface State {
  password: string;
  confirmPassword: string;
  showPassword: boolean;
}

export const ResetPasswordView = () => {
  const classes = makeClasses();
  const params = useSearchParams();

  const { current: usersService } = React.useRef(getService(UsersService));

  const [values, setValues] = React.useState<State>({
    password: '',
    confirmPassword: '',
    showPassword: false
  });

  const { enqueueSnackbar } = useSnackbar();

  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const token = params.get('token');
  if (token === null) {
    enqueueSnackbar(t('auth.resetPassword.noTokenError'), {
      variant: 'error'
    });
    history.push('/user');
    return null;
  }

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });

    if (prop === 'password') {
      setPasswordError(false);
      if (event.target.value === values.confirmPassword) {
        setConfirmPasswordError(false);
      }
    }

    if (prop === 'confirmPassword' && event.target.value === values.password) {
      setConfirmPasswordError(false);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleResetPassword = async () => {
    const handleResetPasswordToken = params.get('token');

    if (handleResetPasswordToken === null) {
      enqueueSnackbar(t('auth.resetPassword.noTokenError'), {
        variant: 'error'
      });
      return;
    }

    if (!values.password) {
      setPasswordError(true);
      enqueueSnackbar(t('auth.resetPassword.emptyPasswordsError'), {
        variant: 'error'
      });
      return;
    }

    if (values.password !== values.confirmPassword) {
      setConfirmPasswordError(true);
      enqueueSnackbar(t('auth.resetPassword.passwordsNotMatch'), {
        variant: 'error'
      });
      return;
    }

    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,}$/g;
    if (!values.password.match(regex)) {
      setConfirmPasswordError(true);
      enqueueSnackbar(t('auth.resetPassword.passwordRegexError'), {
        variant: 'error'
      });
      return;
    }

    try {
      await usersService.resetPassword(values.password, handleResetPasswordToken);
      enqueueSnackbar(t('auth.resetPassword.passwordsChangeSuccess'), {
        variant: 'success'
      });
      history.push('/auth');
    } catch (e) {
      showApiErrors(e, enqueueSnackbar);
    }
  };

  return (
    <>
      <Navigation />
      <Main withHeader withFooter withGradient>
        <div className={classes.container}>
          <h3>{t('auth.resetPassword.title')}</h3>
            <form className={classes.formRoot}>
              <PasswordFormField
                error={passwordError || confirmPasswordError}
                handleChange={handleChange}
                handleClickShowPassword={handleClickShowPassword}
                password={values.password}
                showPassword={values.showPassword}
                label={t('auth.resetPassword.newPasswordLabel')}
              />
              <InputFormField
                error={confirmPasswordError}
                name="confirmPassword"
                handleChange={handleChange}
                value={values.confirmPassword}
                type={values.showPassword ? 'text' : 'password'}
                placeholder={`${t('auth.resetPassword.confirmPasswordLabel')} *`}
              />
            </form>
            <div className={classes.controlButtons}>
              <Button className={classes.button} variant="contained" color="primary" onClick={handleResetPassword}>
                {t('auth.resetPassword.resetPasswordButton')}
              </Button>
            </div>
        </div>
      </Main>
    </>
  );
};

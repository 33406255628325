import React from 'react';

export interface ITenantLogo {
  logoUrl?: string | null,
  logoAlt?: string,
  className?: string,
}

type Props = React.PropsWithChildren<ITenantLogo>;

export const TenantLogo: React.FC<Props> = (props: Props) => {

  return (
    <img
          className={props.className}
          src={props.logoUrl ? props.logoUrl : '/static/img/gu_logo.svg'}
          alt={props.logoAlt}
        />
  );
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'interfaces/themes/default-theme.interfaces';
import { useLocation } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { usePrevious } from 'modules/core/hooks/usePrevious';
export interface IMainProps {
  withHeader?: boolean;
  withFooter?: boolean;
  withGradient?: boolean;
}

const makeClasses = makeStyles<DefaultTheme, Props>(theme => ({
  container: {
    marginTop: '80px',
    marginBottom: '80px'
  },
  main: {
    overflow: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  backgroundLeft: {
    position: 'absolute',
    bottom: '0',
    left: '-80px',
    zIndex: 1,
  },
  backgroundRight: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    zIndex: 1,
  }
}));

type Props = React.PropsWithChildren<IMainProps>;

export interface IMainComponentContext {
  mainRef: HTMLElement | null;
  scrollToTop: () => void;
}

export const MainComponentContext = React.createContext<IMainComponentContext>({
  mainRef: null,
  scrollToTop: () => null,
});

export const Main = (props: Props) => {
  const classes = makeClasses(props);
  const { ref: mainRef, height } = useResizeObserver();
  const prevHeight = usePrevious(height);
  const location = useLocation();

  const scrollToTop = () => {
    if (mainRef.current !== null) {
      mainRef.current.scrollTo(0, 0);
    }
  };

  React.useEffect(() => {
    if (prevHeight === 0 && height !== 0) {
      scrollToTop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevHeight, height]);

  React.useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className={classes.container}>
      <main
        ref={mainRef}
        className={classes.main}
      >
        <MainComponentContext.Provider
          value={{
            mainRef: mainRef.current,
            scrollToTop,
          }}
        >
          {props.children}
        </MainComponentContext.Provider>
      </main>
    </div>
  );
};

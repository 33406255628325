import { AppService, getService } from './index';
import { HttpClientService } from '../functional-services';

export abstract class CoreService {

  constructor (
    public httpClientService = getService(HttpClientService),
    public appService = getService(AppService),
  ) {
    if (!this.bootstrap) {
      throw Error;
    }
  }

  protected async bootstrap() {
    return Promise.resolve();
  }
}
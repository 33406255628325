import styled from 'styled-components';
import { TenantLogo } from 'modules/core/components/common/tenant-logo/tenen-logo';
import { Button } from '@material-ui/core';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

export const Card = styled.div`
  min-width: 600px;
  padding: 0 15px;
  padding-top: 60px;
  & > div:last-child {
    padding-bottom: 0;
  }
  @media (max-width: 768px) {
    min-width: 100%;
  }
`

export const Headline = styled.h3`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
`

export const List = styled.ul`
  padding: 0;
  overflow-y: auto;
  margin: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  margin: 0 0 20px;
  list-style: none;
  position: relative;
`;

export const ListImage = styled(TenantLogo)`
  max-width: 100%;
  display: block;
  max-height: 75px;
`;

export const ListImageWrapper = styled.div`
  width: 150px;
  margin-right: 20px;
`;

export const ListLink = styled.button`
  padding: 24px;
  cursor: pointer;
  margin: 0;
  display: flex;
  list-style: none;
  color: #333;
  padding-right: 140px;
  background: #fff;
  line-height: 50px;
  align-items: center;
  border-radius: 25px;
  border: 0 none;
  width: 100%;
  text-align: left;
  text-decoration: none;
  &:hover {
    background: rgba(0,0,0,0.1);
  }
  @media (max-width: 768px) {
    padding-right: 100px;
  }
`;

export const ListHref = styled.a`
  padding: 24px;
  margin: 0;
  display: flex;
  padding-right: 140px;
  align-items: center;
  list-style: none;
  overflow: hidden;
  background: #fff;
  line-height: 50px;
  border-radius: 25px;
  color: #333;
  text-decoration: none;
  &:hover {
    background: rgba(0,0,0,0.1);
  }
`;

export const Title = styled.strong`
  display: block;
  font-size: 18px;
  font-family: 'niveau-grotesk';
  text-transform: uppercase;
`;

export const Url = styled.em`
  display: block;
  color: #444;
  font-size: 12px;
`;

export const noAccessText = styled.p`
  text-align: center;
  color: #333;
  padding: 34px 20px;
`
export const FooterList = styled.footer`
  padding: 15px;
  overflow: hidden;
`
export const ButtonNav = styled(Button)`
  padding:  0 12px;
  height: 30px;
  margin: 0;
  float: right;
  list-style: none;
  color: #333;
  background: none;
  border: 0 none;
  text-decoration: none;
`;

export const ButtonRestorable = styled.button`
  padding:  0 12px;
  height: 40px;
  position: absolute;
  margin: 0;
  float: right;
  list-style: none;
  color: #323B4C;
  font-family: "niveau-grotesk";
  background: none;
  border-radius: 40px;
  font-size: 14px;
  border: 2px solid #323B4C;
  bottom: 40px;
  right: 20px;
  font-weight: bold;
  text-decoration: none;
`;

export const CardContent = styled.div`
  position: relative;
`;
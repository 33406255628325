import { IConfig } from 'interfaces/config/config.interface';

const DEVELOPMENT = process.env.NODE_ENV === 'development';

// const API_URL_DEVELOPMENT = 'http://localhost:5005/';
// TODO: commented for local development process
const API_URL_DEVELOPMENT = 'https://gro-d-auth-app.azurewebsites.net/';
const API_URL_BUILD = '/';

const API_URL = DEVELOPMENT ? (process.env.REACT_APP_API_URL ?? API_URL_DEVELOPMENT) : API_URL_BUILD;

export const Config: IConfig = {
  API_URL,
  APP_TITLE: 'Grow Uperion',
  STORED_TOKEN_KEY: 'GP_AUTH_JWT',
  STORED_ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  TRANSLATION_ENABLED: false,
  TRANSLATION_NAMESPACE: 'translation',
  DEFAULT_LANGUAGE: 'en'
};

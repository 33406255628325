import React from 'react';
import { Appbar } from 'modules/core/components/common';
import { Logo, UserPanel } from './';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authTokenSelector } from 'modules/core/store/selectors';

export interface NavigationProps {
  noAuthRedirect?: boolean;
}

export const Navigation: React.FC<NavigationProps> = (props) => {
  const storedToken = useSelector(authTokenSelector);
  const history = useHistory();
  return (
    <Appbar>
      <Logo
        onClick={props.noAuthRedirect
          ? undefined
          : () => { history.push('/auth'); }
        }
      />
      <UserPanel visible={storedToken ? true : false} />
    </Appbar>
  );
};

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Main, Section } from 'modules/core/components/layout';
import { LoginCard } from './login-card';
import { Navigation } from 'modules/core/components/layout/nav/navigation';
import { useSearchParams, useService } from 'modules/core/hooks';
import { LoaderView } from 'modules/core/views';
import { AuthService } from 'modules/core/services';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ExternalLoginError } from 'modules/core/views/auth/external-login-error';
import { useSelector } from 'react-redux';
import { authTokenSelector } from 'modules/core/store/selectors';

export interface IAuthData {
  name: string;
  password: string;
}

export interface ILoginViewProps { }

const makeClasses = makeStyles(theme => ({
  authRoot: {
    flex: 1
  },
  container: {
    maxWidth: '1325px',
    paddingTop: 45,
    height: '100vh',
    alignContent: 'center',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center'
  },
  grid: {
    alignItems: 'center',
    height: '75vh',
    justifyContent: 'center',
  },
  loginCard: {
    minWidth: 320,
    // maxWidth: 420
  },
  title: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  }
}));

export const AuthView = (props: ILoginViewProps & RouteComponentProps) => {
  const classes = makeClasses();
  const params = useSearchParams();
  const authToken = params.get('token');
  const authService = useService(AuthService);
  const history = useHistory();
  const storedToken = useSelector(authTokenSelector);
  const [externalLoginError, setExternalLoginError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();
  React.useEffect(
    () => {
      if (authToken) {
        authService.setToken(authToken)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]
  )
  const silentExternalLogin = async () => {
    try {
      // clear query params in url
      window.history.pushState(null, '', '/');
      if (typeof storedToken !== 'boolean') {
        await authService.login(storedToken);
        history.replace(`/user/tenants`);
      }
    } catch (e) {
      // there was an error with singing in with the provided token. Something has gone wrong.
      await authService.logout(); // clear the token from the cache
      setExternalLoginError(true);
    }
  };

  React.useEffect(() => {
    if (storedToken) {
      silentExternalLogin();
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, storedToken]);

  return (
    loading ? <LoaderView background="#FFF" visible text={t('auth.loading')} /> :
      externalLoginError ? <ExternalLoginError /> :
        <>
          <Navigation noAuthRedirect />
          <Main withGradient withFooter withHeader>
            <Section className={classes.authRoot}>
              <div className={classes.container}>
                <Grid container spacing={0} className={classes.grid} justify='center'>
                  <Grid item xs={12} lg={6} className={classes.loginCard}>
                    <LoginCard authWithMicrosoft={true} />
                  </Grid>
                </Grid>
              </div>
            </Section>
          </Main>
        </>
  );
};

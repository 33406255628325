import React from 'react';
import ReactDOM from 'react-dom';
import { ApiError } from 'utils/api-error';
import { OptionsObject, SnackbarKey, SnackbarMessage, SnackbarProvider, useSnackbar } from 'notistack';
import { debugError } from 'utils/debug';

/**
 * Automatically shows snackbar errors from ApiError object.
 * If possible, provide enqueueSnackbar callback from a component as a second parameter to this function.
 * Otherwise this solution creates another SnackbarProvider context, which can collide with SnackbarProvider used by the app.
 * @param error
 * @param enqueueSnackbar
 */
export const showApiErrors = (
  error: ApiError,
  enqueueSnackbar?: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
): boolean => {
  const errorMessages = error?.messages;
  if (!errorMessages) {
    debugError(error);
    return false;
  }
  if (enqueueSnackbar) {
    const errorText = getErrorText(error);
    if (errorText) {
      enqueueSnackbar(errorText, {
        variant: 'error',
        style: { whiteSpace: 'pre-line' }
      });
    }
    return !!errorText;
  } else {
    return showErrors(error.messages || []);
  }
};

export const showErrors = (messages: string[]): boolean => {
  const texts = stackTexts(messages);
  if (texts) {
    ReactDOM.render(
      <SnackbarProvider maxSnack={3}>
        <ErrorSnackbar
          message={texts}
        />
      </SnackbarProvider>
      , document.getElementById('snackbar'));
  }
  return !!texts;
};

export const getErrorText = (error: ApiError) => stackTexts(error?.messages);

export const stackTexts = (texts: string[]) => {
  return texts?.reduce((payload, current) => {
    if (payload.length) {
      return `${payload}\n${current}`
    }
    return current;
  }, '');
};

export interface ErrorSnackbarProps {
  message: string;
}

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({ message }) => {
  const { enqueueSnackbar } = useSnackbar();
  enqueueSnackbar(message, {
    variant: 'error',
    style: { whiteSpace: 'pre-line' }
  });
  return null;
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormField } from 'modules/core/components/common';

const makeClasses = makeStyles(theme => ({
  rootInput: {
    width: '100%',
    paddingRight: 0

  },
  visibility: {
    opacity: '0.5'
  },
  inputAdornment: {
    position: 'absolute',
    right: '12px',
    top: '50%',
    paddingRight: 0
  }
}));

export interface PasswordFormFieldProps {
  handleChange: (stateValue: any) => (event: React.ChangeEvent<HTMLInputElement>) => any;
  handleClickShowPassword: (event: React.MouseEvent<HTMLElement>) => any;
  password: string;
  showPassword: boolean;
  name?: string;
  label?: string;
  error?: boolean;
  required?: boolean;
}

export const PasswordFormField = ({
  error,
  handleChange,
  handleClickShowPassword,
  password,
  showPassword,
  name,
  label,
  required
}: PasswordFormFieldProps) => {
  const classes = makeClasses();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormField>
      <OutlinedInput
        // id="outlined-adornment-password"
        error={error}
        className={classes.rootInput}
        type={showPassword ? 'text' : 'password'}
        value={password}
        placeholder={`${label} *`}
        name={name}
        onChange={handleChange(name)}
        required={required}
        endAdornment={
          <InputAdornment position="end" className={classes.inputAdornment}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <Visibility className={classes.visibility} />
              ) : (
                <VisibilityOff className={classes.visibility} />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormField>
  );
};

PasswordFormField.defaultProps = {
  label: 'Password',
  name: 'password'
};

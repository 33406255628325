import React from 'react';
import { LoaderView } from 'modules/core/views';
import { useService } from 'modules/core/hooks';
import { AuthService } from 'modules/core/services';
import { useHistory } from 'react-router-dom';

// this view is needed to fire logout triggered by external auth provider
export const SignOutView = () => {
  const history = useHistory();
  const authService = useService(AuthService);

  authService.logout(false).then(() => {
    history.push('/auth');
  });

  return (
    <LoaderView visible />
  );
};
